import React, { Fragment } from 'react';
import { List } from 'semantic-ui-react';

import { getApplicantLabel } from '../../../utils/applicantlabel';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-evenly',
  padding: '0 10%'
};

const normalizeKey = keyName => {
  const keyArray = keyName.split('_');

  return `${keyArray[0][0].toUpperCase()}${keyArray[0].slice(1)} ${keyArray.slice(1)}`;
}

const stringifyDataArray = (data) => {
  if (typeof data !== 'object')
    return data;

  if (Array.isArray(data))
    return (
      <ul>
        {
          data.map((item, key) => {
            if (typeof item === 'object') {
              const keys = Object.keys(item);

              return (
                <Fragment key={`applicant-${key}`}>
                  <li>{getApplicantLabel(key)}</li>
                  {
                    keys.map((value, key) => {
                      return (
                        <Fragment key={`object-${key}`}>
                          <ul>
                            {item[value] &&
                              <li>{`${normalizeKey(value)}: ${item[value]}`}</li>
                            }
                          </ul>
                        </Fragment>
                      )
                    })
                  }
                </Fragment>
              );
            } else {
              return (
                <li key={`array-${key}`}>{item}</li>
              );
            }
          })
        }
      </ul >
    );

  return (
    <ul>
      {
        Object.keys(data).map((key) => {
          return (
            <li key={key}>{`${normalizeKey(key)}: ${data[key]}`}</li>
          )
        })
      }
    </ul>
  );
}

const SelectionSummary = ({ data }) => {
  let components = 'Loading...';
  if (data) {
    components = (
      <List style={styles} divided relaxed>
        {Object.keys(data).map((key) => {
          return (
            <List.Item key={key}>
              <List.Content>
                <List.Header>
                  {normalizeKey(key)}
                </List.Header>
                <List.Description>
                  {stringifyDataArray(data[key])}
                </List.Description>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    );
  }

  return (
    <Fragment>
      {components}
    </Fragment>
  );
}

export default SelectionSummary;