import React, { Fragment, useEffect, useContext } from "react";
import TileSelector from "../../shared/TileSelector";
import Tile from "../../shared/Tile";

import { Context } from "../../../contexts/AppContext";
import { types } from "../../../hooks/reducers/useAppReducer";

const SelectBroker = (props) => {
  const { dispatch } = useContext(Context);

  useEffect(() => {
    return dispatch({
      type: types.IS_FINISHED,
    });
  }, []);

  return (
    <Fragment>
      <h1>Select</h1>
      <p>Please select a broker...</p>

      <TileSelector {...props}>
        <Tile value="Chris Berri" />

        <Tile value="Broker 2" />

        <Tile value="Broker 3" />
      </TileSelector>
    </Fragment>
  );
};

export default SelectBroker;
