import React from 'react';

//#region Steps imports
//#region AssetFinance imports
import AssetFinance from './basic/asset-finance/AssetFinance';
import AssetPurchaseMode from './basic/asset-finance/AssetPurchaseMode';
import AssetIncomeType from './basic/asset-finance/AssetIncomeType';
//#endregion AssetFinance

//#region Residential imports
import ApplicantsNumber from './basic/residential/ApplicantsNumber';
import ResidentialType from './basic/residential/ResidentialType';
import PropertyQuantity from './basic/residential/PropertyQuantity';
import RefinanceReason from './basic/residential/RefinanceReason';
import TotalLendingAmount from './basic/residential/TotalLendingAmount';
import CurrentRate from './basic/residential/CurrentRate';
import EstimatedMarketValue from './basic/residential/EstimatedMarketValue';
import ProjectType from './basic/residential/ProjectType';
import IncomeTypeContainer from './basic/residential/IncomeTypeContainer';
import SMSFType from './basic/residential/SMSFType';
import EmploymentType from './basic/residential/EmploymentType';
import TraderType from './basic/residential/TraderType';
//#endregion Residential

//#region Commercial imports
import CommercialProject from './basic/commercial/CommercialProject';
import CommercialType from './basic/commercial/CommercialType';
import ConstructionFunding from './basic/commercial/ConstructionFunding';
//#endregion Commercial

//#region BasicInformation imports
import BasicDetails from './basic/basic-information/BasicDetails';
import EnquiryDetails from './basic/basic-information/EnquiryDetails';
import SelectBroker from './basic/basic-information/SelectBroker';
import AuthorizeInformationUsage from './basic/basic-information/AuthorizeInformationUsage';
//#endregion BasicInformation

//#region Shared imports
import Welcome from './basic/Welcome';
import FinalChoise from './shared/FinalChoise';
import FinishApplication from './shared/FinishApplication';
//#endregion Shared
//#endregion Steps

//#region getters
const getBasicFlowSteps = () => {
  return [
    <BasicDetails keyName='basic_details' allowNextStep />,
    <EnquiryDetails keyName='enquiry_details' allowNextStep />,
    <FinalChoise keyName='final_choise' />,
    <SelectBroker keyName='select_broker' />,
    <AuthorizeInformationUsage keyName='authorize_information_usage' allowNextStep />,
    <FinishApplication keyName='finish' />
  ];
}

export const getInitSteps = () => {
  return [
    <Welcome keyName='path' />
  ];
}

export const getAssetFinancialSteps = () => {
  return [
    <AssetFinance keyName='asset_finance' />,
    <AssetPurchaseMode keyName='asset_purchase_mode' />,
    <AssetIncomeType keyName='asset_income_type' />,
    <EmploymentType keyName='asset_employment_type' />,
    ...getBasicFlowSteps()
  ];
}

export const getResidentialSteps = () => {
  return [
    <ApplicantsNumber keyName='applicants_number' />,
    <ResidentialType keyName='residential_type' />,
    <PropertyQuantity keyName='property_quantity' />,
    <RefinanceReason keyName='refinance_reason' />,
    <TotalLendingAmount keyName='total_lending_amount' allowNextStep />,
    <EstimatedMarketValue keyName='estimated_market_value' allowNextStep />,
    <CurrentRate keyName='current_rate' allowNextStep />,
    <ProjectType keyName='project_type' />,
    <SMSFType keyName='smsf_type' />,
    <IncomeTypeContainer keyName='income' allowNextStep />,
    ...getBasicFlowSteps()
  ];
}

export const getOtherSteps = () => {
  return [
    ...getBasicFlowSteps()
  ];
}

export const getCommercialSteps = () => {
  return [
    <CommercialProject keyName='commercial_project' />,
    <CommercialType keyName='commercial_type' />,
    <ConstructionFunding keyName='construction_funding' />,
    ...getBasicFlowSteps()
  ];
}
//#endregion getters