import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const AssetPurchaseMode = props => {
  return (
    <Fragment>
      <h1>Asset Purchase</h1>
      <p>How are you wanting to purchase the asset?</p>

      <TileSelector {...props}>
        <Tile value="ABN/ACN Business" />

        <Tile value="Personal Name" />
      </TileSelector>
    </Fragment>
  );
}

export default AssetPurchaseMode;