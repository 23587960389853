import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import {
  propertyTypeOptions,
  zoningOptions,
  ownershipOptions,
  yesNoOptions
} from '../../../utils/selectOptions';

const state = {
  data: {
    address: '',
    value: '',
    type: '',
    montlhy_rental_income: '',
    zoning: '',
    ownership: '',
    to_be_sold: '',
    will_it_become_investment_after_settlement: '',
    anticipated_monthly_rental_income: ''
  },
  errors: {}
};

const schema = {
  address: Joi.string().required().label('Owner Occupied Property Address'),
  value: Joi.number().required().label('Value'),
  type: Joi.string().required().label('Property Type'),
  zoning: Joi.string().required().label('Zoning'),
  ownership: Joi.string().required().label('Ownership'),
  to_be_sold: Joi.boolean().label('To be sold?'),
  will_it_become_investment_after_settlement: Joi.boolean().required().label('Will it become investment after settlement?'),
  anticipated_monthly_rental_income: Joi.number().label('If Yes, Anticipated Monthly Rental Income:')
};

const OwnerOccupiedPropertyDetailsForm = ({ handleChange, values, errors }) => {
  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomInput
            name='address'
            label='Owner Occupied Property Address'
            value={values ? values['address'] : null}
            error={errors['address']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='value'
            label='Value'
            value={values ? values['value'] : null}
            error={errors['value']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='type'
            label='Property Type'
            options={propertyTypeOptions}
            value={values ? values['type'] : null}
            error={errors['type']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='zoning'
            label='Zoning'
            options={zoningOptions}
            value={values ? values['zoning'] : null}
            error={errors['zoning']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='ownership'
            label='Ownership'
            options={ownershipOptions}
            value={values ? values['ownership'] : null}
            error={errors['ownership']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='to_be_sold'
            label='To be sold?'
            options={yesNoOptions}
            value={values ? values['to_be_sold'] : null}
            error={errors['to_be_sold']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='will_it_become_investment_after_settlement'
            label='Will it become investment after settlement?'
            options={yesNoOptions}
            value={values ? values['will_it_become_investment_after_settlement'] : null}
            error={errors['will_it_become_investment_after_settlement']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='anticipated_monthly_rental_income'
            label='If Yes, Anticipated Monthly Rental Income:'
            value={values ? values['anticipated_monthly_rental_income'] : null}
            error={errors['anticipated_monthly_rental_income']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>
    </Fragment>
  )
}

export default withForm(OwnerOccupiedPropertyDetailsForm, state, schema);