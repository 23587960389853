import React, { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';

const CustomCheckBox = ({ name, error, handleChange, checked, ...rest }) => {
  const [stateChecked, setStateChecked] = useState(checked);
  const handleOnChange = () => {
    setStateChecked(!stateChecked);

    handleChange({ currentTarget: { name, value: !stateChecked } });
  }

  return (
    <div className="form-group">
      {/*<Label pointing='below' prompt>{label}</Label>*/}
      <Checkbox
        name={name}
        checked={stateChecked}
        onChange={handleOnChange}
        {...rest}
      />
      {/*error && <span className='alert alert-danger'>{error}</span>*/}
    </div>
  );
}

export default CustomCheckBox;