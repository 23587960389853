import { useState, useEffect, useContext, useRef } from 'react';

import { Context } from '../contexts/EasyFlowContext';
import { types } from './reducers/useEasyFlowReducer';

const useStatefulStep = (keyName, setter) => {
  const { state: { data: { [keyName]: dataValue } }, dispatch } = useContext(Context);
  const [value, setValue] = useState(dataValue ?? 0);
  const valueRef = useRef(value);
  const firstUpdate = useRef(true);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      setTimeout(() => {
        setter(true);
      }, 500);
    }

    return () => dispatch({
      type: types.SAVE_DATA,
      payload: {
        keyName,
        value: valueRef.current
      }
    });
  }, []);

  return [value, setValue];
}

export default useStatefulStep;