import React, { Fragment, useState, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import CustomCheckBox from '../../shared/controls/CustomCheckBox';
import CustomDatePicker from '../../shared/controls/CustomDatePicker';
import {
  bankOptions,
  repaymentTypeOptions,
  otherLoanOptions
} from '../../../utils/selectOptions';

const state = {
  data: [],
  errors: {}
};

const schema = {
  type: Joi.string().required().label('Type'),
  lender: Joi.string().label(`Lender`),
  limit: Joi.number().label('Limit'),
  balance: Joi.number().label('Balance'),
  monthly_repayment: Joi.number().label('Monthly Repayment'),
  /* bsb: Joi.string().label('BSB'),
  account_number: Joi.number().label('Account Number'),
  interest_rate: Joi.number().label('Interest Rate'),
  expiry_date: Joi.date().label('Loan Term Expiry Date'),
  repayment_type: Joi.string().label('Repayment Type'),
  linked_asset: Joi.string().label('Linked Asset'),
  refinance: Joi.boolean().label('Refinance?') */
};

//#region form-renders
const renderPersonalLoans = (length, handleChange, data, errors) => {
  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='lender'
            label={`Personal Loan ${length} - Lender`}
            options={bankOptions}
            data={data}
            error={errors['lender']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='limit'
            label='Limit'
            data={data}
            error={errors['limit']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomInput
            name='balance'
            label='Balance'
            data={data}
            error={errors['balance']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='monthly_repayment'
            label='Monthly Repayment'
            data={data}
            error={errors['monthly_repayment']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      {/* <FormRow>
        <FormColumn>
          <CustomInput
            name='bsb'
            label='BSB'
            data={data}
            error={errors['bsb']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='account_number'
            label='Account Number'
            data={data}
            error={errors['account_number']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='interest_rate'
            label='Interest Rate'
            data={data}
            error={errors['interest_rate']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDatePicker
            name={'expiry_date'}
            label='Expiry Date'
            data={data}
            error={errors['expiry_date']}
            handleChange={handleChange}
          />
        </FormColumn>
        <FormColumn>
          <CustomCheckBox
            name='refinance'
            label='Refinance?'
            data={data}
            error={errors['refinance']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow> */}
    </Fragment>
  );
}

const renderSMSFLoans = (length, handleChange, data, errors) => {
  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='lender'
            label={`SMSF ${length} - Lender`}
            options={bankOptions}
            data={data}
            error={errors['lender']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='limit'
            label='Limit'
            data={data}
            error={errors['limit']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomInput
            name='balance'
            label='Balance'
            data={data}
            error={errors['balance']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='monthly_repayment'
            label='Monthly Repayment'
            data={data}
            error={errors['monthly_repayment']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      {/* <FormRow>
        <FormColumn>
          <CustomInput
            name='bsb'
            label='BSB'
            data={data}
            error={errors['bsb']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='account_number'
            label='Account Number'
            data={data}
            error={errors['account_number']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='interest_rate'
            label='Interest Rate'
            data={data}
            error={errors['interest_rate']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDatePicker
            name={'expiry_date'}
            label='Expiry Date'
            data={data}
            error={errors['expiry_date']}
            handleChange={handleChange}
          />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='repayment_type'
            label='Repayment Type'
            options={repaymentTypeOptions}
            data={data}
            error={errors['repayment_type']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='linked_asset'
            label='Linked Asset'
            data={data}
            error={errors['linked_asset']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomCheckBox
            name='refinance'
            label='Refinance?'
            data={data}
            error={errors['refinance']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow> */}
    </Fragment>
  );
}

const renderStudentLoans = (length, handleChange, data, errors) => {
  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomInput
            name='balance'
            label='Balance'
            data={data}
            error={errors['balance']}
            handleChange={handleChange} />
        </FormColumn>
        {/* <FormColumn>
          <CustomDropdown
            name='lender'
            label={`SMSF ${length} - Lender`}
            options={bankOptions}
            data={data}
            error={errors['lender']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='monthly_repayment'
            label='Monthly Repayment'
            data={data}
            error={errors['monthly_repayment']}
            handleChange={handleChange} />
        </FormColumn> */}
      </FormRow>
    </Fragment>
  );
}

const switchRender = (loanType, length, handleChange, data, errors) => {
  switch (loanType) {
    case 'Personal Loan':
      return renderPersonalLoans(length, handleChange, data, errors);

    case 'SMSF':
      return renderSMSFLoans(length, handleChange, data, errors);

    case 'Student Loan':
      return renderStudentLoans(length, handleChange, data, errors);

    default:
      return null;
  }
}
//#endregion

const OtherLoansForm = ({ handleChange, values, errors, otherLoansLength }) => {
  const [loanType, setLoanType] = useState(null);
  useEffect(() => {
    state.data = values;
  }, [values]);

  const onHandleChange = (item) => {
    setLoanType(item.currentTarget.value);
    handleChange(item);
  }

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='type'
            label='Type'
            options={otherLoanOptions}
            value={values ? values['type'] : null}
            error={errors['type']}
            handleChange={onHandleChange} />
        </FormColumn>
      </FormRow>

      {switchRender(loanType, otherLoansLength, handleChange, values, errors)}
    </Fragment>
  );
}

export default withForm(OtherLoansForm, state, schema);