import React, { useState } from 'react';

const styles = {
  selected: {
    borderWidth: '5px',
    backgroundColor: '#f9f9f9'
  }
};

const Tile = ({ value, isSelected, onSelect, children }) => {
  return (
    <li className='tile' style={isSelected ? styles.selected : null} onClick={() => onSelect(value)}>
      {children}
      {value}
    </li>
  );
};


export default Tile;