import React, { useContext } from 'react';
import BasicInformationForm from './applicant/BasicInformationForm';
import ContactDetailsForm from './applicant/ContactDetailsForm';
import ResidentialDetailsForm from './applicant/ResidentialDetailsForm';
import EmploymentDetailsForm from './applicant/EmploymentDetailsForm';
import IncomeForm from './applicant/IncomeForm';
import AdvancedStepper from '../shared/AdvancedStepper';
import AccordionMapper from '../shared/AccordionMapper';

import { Context as AppContext } from '../../contexts/AppContext';
import { types } from '../../hooks/reducers/useAppReducer';

const ApplicantStepper = ({ section, mainDispatch = null }) => {
  const { state: { advancedInfo: { applicants } }, dispatch: appDispatch } = useContext(AppContext);

  /*const getCollectionPairs = (applicants) => {
    return applicants.map(applicant => {
      const { basic_information, contact_details } = applicant;

      return ([
        { label: 'Name', content: `${basic_information.title} ${basic_information.first_name} ${basic_information.surname}` },
        { label: 'Mobile Phone', content: `${contact_details.mobile_phone}` },
        { label: 'Email', content: `${contact_details.primary_email}` }
      ]);
    });
  }*/

  const getSteps = () => {
    return [
      'Basic Information',
      'Contact Details',
      'Residential Details',
      'Employment Details',
      'Income (Annual)'
    ];
  }

  const getStepContent = (step) => {
    let content = null

    switch (step) {
      case 1:
        content = {
          propKeyName: 'contact_details',
          formComponent: ContactDetailsForm
        };
        break;

      case 2:
        content = {
          propKeyName: 'residential_details',
          formComponent: ResidentialDetailsForm
        };
        break;

      case 3:
        content = {
          propKeyName: 'employment_details',
          formComponent: EmploymentDetailsForm
        };
        break;

      case 4:
        content = {
          propKeyName: 'income',
          formComponent: IncomeForm
        };
        break;

      default:
        content = {
          propKeyName: 'basic_information',
          formComponent: BasicInformationForm
        };
        break;
    }

    return <AccordionMapper
      isMainProp
      section={section}
      baseObject={applicants}
      appDispatch={appDispatch}
      types={types}
      {...content} />
  }

  return (
    <AdvancedStepper
      steps={getSteps()}
      getStepContent={getStepContent}
      mainDispatch={mainDispatch} />
  );
}

export default ApplicantStepper;