import React, { Fragment, useContext } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';
import CustomLoader from '../../shared/Loader';

import { Context } from '../../../contexts/EasyFlowContext';

const ProjectType = props => {
  const { state: { data: { residential_type } } } = useContext(Context);

  let components = null;
  if (residential_type) {
    components = (
      <Fragment>
        <p>Select the type of project</p>

        <TileSelector {...props}>
          {(residential_type[0] === 'First Home Buyer/Owner Occupier Purchase' || residential_type[0] === 'Investor') &&
            <Fragment>
              <Tile value='Established' />

              <Tile value='Construction' />

              <Tile value='Off the plan' />
            </Fragment>
          }

          {residential_type[0] === 'Retirement' &&
            <Tile value='Reverse Mortgage' />
          }

          {(residential_type[0] === 'Retirement' || residential_type[0] === 'Investor') &&
            <Tile value='SMSF' />
          }
        </TileSelector>
      </Fragment>
    );
  } else {
    components = <CustomLoader />;
  }

  return (
    components
  );
}
export default ProjectType;