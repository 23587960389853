import React, { useState, useEffect, useContext, useRef } from 'react';
import { Context } from '../../../contexts/EasyFlowContext';
import { types } from '../../../hooks/reducers/useEasyFlowReducer';
import useTimeout from '../../../hooks/useTimeout';

import { Checkbox, Form, TextArea } from 'semantic-ui-react';
import Slider from '@material-ui/core/Slider';


const marks = [
  {
    value: 1,
    label: 1
  }, {
    value: 30,
    label: 30
  }, {
    value: 60,
    label: 60
  }, {
    value: 90,
    label: 90
  }, {
    value: 120,
    label: 120
  }
];

const EnquiryDetails = ({ keyName, allowNextStep }) => {
  //#region hooks
  const { state: { data: { [keyName]: values } }, dispatch } = useContext(Context);
  const [creditCheck, setCreditCheck] = useState(values?.creditCheck ?? false);
  const [financeUrgency, setFinanceUrgency] = useState(values?.financeUrgency ?? 60);
  const [notes, setNotes] = useState(values?.notes ?? '');

  const dataRef = useRef({
    creditCheck,
    financeUrgency,
    notes
  });

  const firstUpdate = useRef(true);
  //#endregion hooks

  //#region useEffects
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      setTimeout(() => {
        allowNextStep(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    dataRef.current = {
      creditCheck,
      financeUrgency,
      notes
    };
  }, [creditCheck, financeUrgency, notes]);

  useEffect(() => {
    return () => dispatch({
      type: types.SAVE_DATA,
      payload: {
        keyName,
        value: { ...dataRef.current }
      }
    })
  }, []);
  //#endregion useEffects

  return (
    <Form className='f-c-center'>
      <p>Details of enquiry</p>

      <Form.Field
        control={Checkbox}
        label={<label>Have you had any defaults or missed payments or overdue accounts?</label>}
        checked={creditCheck}
        onChange={(e, data) => setCreditCheck(data.checked)}
      />

      <Form.Field inline style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <label>Urgency of finance (In Days)</label>
        <Slider
          min={1}
          max={120}
          step={null}
          value={financeUrgency}
          aria-labelledby="discrete-slider-custom"
          valueLabelDisplay="auto"
          marks={marks}
          onChange={(e, value) => setFinanceUrgency(value)} />
      </Form.Field>

      <Form.Field
        control={TextArea}
        label='Notes'
        placeholder='Any notes?'
        value={notes}
        onChange={e => setNotes(e.target.value)}
      />
    </Form>
  );
}

export default EnquiryDetails;