import React, { Fragment, useState, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import CustomDatePicker from '../../shared/controls/CustomDatePicker';
import { titleOptions, genderOptions, maritalStatusOptions } from '../../../utils/selectOptions';
import CustomCheckBox from '../../shared/controls/CustomCheckBox';

import useSelectOptionsQuery from '../../../hooks/useSelectOptionsQuery';

const state = {
  data: {},
  errors: {}
};

const schema = {
  title: Joi.string().required().label('Title'),
  first_name: Joi.string().min(3).max(20).required().label('First name'),
  middle_name: Joi.string().allow('').min(3).max(20).label('Middle name'),
  surname: Joi.string().min(3).max(20).required().label('Surname'),
  preferred_name: Joi.string().allow('').min(3).max(20).label('Preferred name'),
  previous_name: Joi.string().allow('').min(3).max(20).label('Previous name'),
  gender: Joi.string().required().label('Gender'),
  dob: Joi.date().required().label('Date of Birth'),
  marital_status: Joi.string().max(20).required().label('Marital Staus'),
  spouse_full_name: Joi.string().max(50).label('Spouse Full Name'),
  has_dependants: Joi.boolean().label('Has dependants?'),
  mothers_maiden_name: Joi.string().max(20).label('Mother`s Maiden Name'),
  number_dependents: Joi.number().label('Number of Dependents'),
  names_dependents: Joi.array().items(Joi.string()).label('Names of Dependents'),
  birth_dependents: Joi.array().items(Joi.date()).label('Dependents Date of Birth'),
};

const BasicInformationForm = ({ handleChange, values, errors }) => {
  const [maritalStatus, setMaritalStatus] = useState(values ? values['marital_status'] : null);
  const [hasDependants, setHasDependants] = useState(values ? values['has_dependants'] : null);
  const { loading, error, data } = useSelectOptionsQuery('Title');

  const updateMaritalStatus = (data) => {
    const { currentTarget: { value } } = data;

    setMaritalStatus(value);
    handleChange(data);
  }

  const updateHasDependants = (data) => {
    const { currentTarget: { value } } = data;

    setHasDependants(value);
    handleChange(data);
  }

  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          {!loading && data &&
            <CustomDropdown
              name='title'
              label='Title'
              options={data.selectOptions[0]['options']}
              value={values ? values['title'] : null}
              error={errors['title']}
              handleChange={handleChange} />}
        </FormColumn>

        <FormColumn>
          <CustomInput
            name='first_name'
            label='First Name'
            value={values ? values['first_name'] : null}
            error={errors['first_name']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomInput
            name='middle_name'
            label='Middle Name'
            value={values ? values['middle_name'] : null}
            error={errors['middle_name']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomInput
            name='surname'
            label='Surname'
            value={values ? values['surname'] : null}
            error={errors['surname']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='gender'
            label='Gender'
            options={genderOptions}
            value={values ? values['gender'] : null}
            error={errors['gender']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomDatePicker
            name={'dob'}
            label='Date of Birth'
            value={values ? values['dob'] : null}
            error={errors['dob']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='marital_status'
            label='Marital Staus'
            options={maritalStatusOptions}
            value={values ? values['marital_status'] : null}
            error={errors['marital_status']}
            handleChange={updateMaritalStatus} />
        </FormColumn>

        {(maritalStatus === 'De Facto' ||
          maritalStatus === 'Married') &&

          <Fragment>
            <FormColumn>
              <CustomInput
                name='spouse_full_name'
                label='Spouse Full Name'
                value={values ? values['spouse_full_name'] : null}
                error={errors['spouse_full_name']}
                handleChange={handleChange} />
            </FormColumn>

            <FormColumn>
              <CustomCheckBox
                toggle
                checked={hasDependants}
                name='has_dependants'
                label='Have you got any dependants?'
                value={values ? values['has_dependants'] : null}
                handleChange={updateHasDependants}
              />
            </FormColumn>
          </Fragment>
        }
      </FormRow>

      {hasDependants &&
        <FormRow>
          <FormColumn>
            <CustomInput
              name='number_dependents'
              label='Number of Dependents'
              value={values ? values['number_dependents'] : null}
              error={errors['number_dependents']}
              handleChange={handleChange} />
          </FormColumn>

          <FormColumn>
            <CustomInput
              name='names_dependents'
              label='Names of Dependents'
              value={values ? values['names_dependents'] : null}
              error={errors['names_dependents']}
              handleChange={handleChange} />
          </FormColumn>

          <FormColumn>
            <CustomInput
              name='birth_dependents'
              label='Dependents Date of Birth'
              value={values ? values['birth_dependents'] : null}
              error={errors['birth_dependents']}
              handleChange={handleChange} />
          </FormColumn>
        </FormRow>
      }
    </Fragment>
  );
}

export default withForm(BasicInformationForm, state, schema);