import React, { useRef } from 'react';
import { useQuery } from 'graphql-hooks';
import { useEffect } from 'react';

const OPTIONS_QUERY = `query SelectOptions($name: String) {
  selectOptions(where: { name: $name }) {
    name
    options
  }
}`;

const useSelectOptionsQuery = (name) => {
  const queryRef = useRef();
  const { loading, error, data } = useQuery(OPTIONS_QUERY, {
    variables: {
      name
    }
  });

  queryRef.current = { loading, error, data };

  useEffect(() => {
    queryRef.current = { loading, error, data };

    /* if (queryRef.current.data && queryRef.current.data.selectedOptions) {
      return queryRef.current.data.selectedOptions['options'];
    } */
  }, [data]);

  return queryRef.current;
}

export default useSelectOptionsQuery;