import React, { Fragment, useContext } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

import { Context } from '../../../contexts/EasyFlowContext';

const AssetIncomeType = ({ purchaseMode, ...rest }) => {
  const { state: { data: { asset_purchase_mode } } } = useContext(Context);

  const components = (
    asset_purchase_mode[0] === 'Personal Name' ?
      <Tile value='PAYG/Employed/(Payslips)' /> :
      <Fragment>
        <Tile value='Sole Trader' />

        <Tile value='Company' />

        <Tile value='Trust' />
      </Fragment>
  );

  return (
    <Fragment>
      <TileSelector {...rest}>
        {components}
      </TileSelector>
    </Fragment>
  );
}

export default AssetIncomeType;