import React, { useContext } from 'react';
import MortgageLoansForm from './liabilities/MortgageLoansForm';
import CreditCardsForm from './liabilities/CreditCardsForm';
import VehicleLoansForm from './liabilities/VehicleLoansForm';
import OtherLoansForm from './liabilities/OtherLoansForm';
import AdvancedStepper from '../shared/AdvancedStepper';
import AccordionMapper from '../shared/AccordionMapper';

import { Context as AppContext } from '../../contexts/AppContext';

const LiabilitiesStepper = ({ section, mainDispatch }) => {
  const { state: {
    advancedInfo: { liabilities },
    basicInfo: { residential_type }
  }, dispatch: appDispatch } = useContext(AppContext);

  const isFirstTimeBuyer = residential_type === 'First Home Buyer/Owner Occupier Purchase';

  const getSteps = () => {
    let steps = [
      'Mortgage Loans',
      'Credit Cards',
      'Vehicle Loans',
      'Other Loans'
    ];

    if (isFirstTimeBuyer) return steps.slice(1, steps.length);

    return steps;
  }

  const getStepContent = (step) => {
    if (isFirstTimeBuyer) {
      switch (step) {
        case 1:
          return <AccordionMapper
            isAdditionable
            section={section}
            baseObject={liabilities['vehicle_loans']}
            appDispatch={appDispatch}
            propKeyName='vehicle_loans'
            formComponent={VehicleLoansForm} />

        case 2:
          return <AccordionMapper
            isAdditionable
            section={section}
            baseObject={liabilities['other_loans']}
            appDispatch={appDispatch}
            propKeyName='other_loans'
            formComponent={OtherLoansForm} />

        default:
          return <AccordionMapper
            isAdditionable
            section={section}
            baseObject={liabilities['credit_cards']}
            appDispatch={appDispatch}
            propKeyName='credit_cards'
            formComponent={CreditCardsForm} />;
      }
    } else {
      switch (step) {
        case 1:
          return <AccordionMapper
            isAdditionable
            section={section}
            baseObject={liabilities['credit_cards']}
            appDispatch={appDispatch}
            propKeyName='credit_cards'
            formComponent={CreditCardsForm} />;

        case 2:
          return <AccordionMapper
            isAdditionable
            section={section}
            baseObject={liabilities['vehicle_loans']}
            appDispatch={appDispatch}
            propKeyName='vehicle_loans'
            formComponent={VehicleLoansForm} />;

        case 3:
          return <AccordionMapper
            isAdditionable
            section={section}
            baseObject={liabilities['other_loans']}
            appDispatch={appDispatch}
            propKeyName='other_loans'
            formComponent={OtherLoansForm} />;

        default:
          return <AccordionMapper
            isAdditionable
            section={section}
            baseObject={liabilities['mortgage_loans']}
            appDispatch={appDispatch}
            propKeyName='mortgage_loans'
            formComponent={MortgageLoansForm} />;
      }
    }
  }

  return (
    <AdvancedStepper
      steps={getSteps()}
      getStepContent={getStepContent}
      mainDispatch={mainDispatch} />
  );
}

export default LiabilitiesStepper;