import React, { Fragment } from 'react';
import TileSelectorWithArray from '../../shared/TileSelectorWithArray';
import Tile from '../../shared/Tile';

const TraderType = props => (
  <Fragment>
    <TileSelectorWithArray {...props}>
      <Tile value='Sole Trader' />

      <Tile value='Company' />

      <Tile value='Trust' />
    </TileSelectorWithArray>
  </Fragment>
);

export default TraderType;