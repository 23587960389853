import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import CustomCheckBox from '../../shared/controls/CustomCheckBox';
import CustomDatePicker from '../../shared/controls/CustomDatePicker';
import {
  bankOptions,
  mortgageTypeOptions,
  repaymentTypeOptions,
  ownershipOptions,
} from '../../../utils/selectOptions';

const state = {
  data: {},
  errors: {}
};

const schema = {
  lender: Joi.string().label(`Lender`),
  mortgage_type: Joi.string().label('Mortgage Type'),
  ownership: Joi.string().label('Ownership'),
  balance: Joi.number().label('Balance'),
  /* bsb: Joi.number().label('BSB'),
  account_number: Joi.number().label('Account Number'),
  interest_rate: Joi.number().label('Interest Rate'),
  monthly_repayment: Joi.number().label('Monthly Repayment'),
  expiry_date: Joi.date().label('Loan Term Expiry Date'),
  limit: Joi.number().label('Limit'),
  repayment_type: Joi.string().label('Repayment Type'),
  refinance: Joi.boolean().label('Refincance?') */
};

const MortgageLoansForm = ({ handleChange, values, errors, mortgageLoansLength }) => {
  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='lender'
            label={`Mortgage Loan ${mortgageLoansLength} - Lender`}
            options={bankOptions}
            value={values ? values['lender'] : null}
            error={errors['lender']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='mortgage_type'
            label='Mortgage Type'
            options={mortgageTypeOptions}
            value={values ? values['mortgage_type'] : null}
            error={errors['mortgage_type']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='ownership'
            label='Ownership'
            options={ownershipOptions}
            value={values ? values['ownership'] : null}
            error={errors['ownership']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='balance'
            label='Balance'
            value={values ? values['balance'] : null}
            error={errors['balance']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      {/* <FormRow>
        <FormColumn>
          <CustomInput
            name='limit'
            label='Limit'
            value={values ? values['limit'] : null}
            error={errors['limit']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='monthly_repayment'
            label='Monthly Repayment'
            value={values ? values['monthly_repayment'] : null}
            error={errors['monthly_repayment']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='bsb'
            label='BSB'
            value={values ? values['bsb'] : null}
            error={errors['bsb']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='account_number'
            label='Account Number'
            value={values ? values['account_number'] : null}
            error={errors['account_number']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='interest_rate'
            label='Interest Rate'
            value={values ? values['interest_rate'] : null}
            error={errors['interest_rate']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDatePicker
            name={'expiry_date'}
            label='Loan Term Expiry Date'
            value={values ? values['expiry_date'] : null}
            error={errors['expiry_date']}
            handleChange={handleChange}
          />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='repayment_type'
            label='Repayment Type'
            options={repaymentTypeOptions}
            value={values ? values['repayment_type'] : null}
            error={errors['repayment_type']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomCheckBox
            name='refinance'
            label='Refinance?'
            checked={values ? values['balance'] : null}
            error={errors['balance']}
            handleChange={handleChange}
          />
        </FormColumn>
      </FormRow> */}
    </Fragment>
  );
}

export default withForm(MortgageLoansForm, state, schema);