import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { Tab } from "semantic-ui-react";
import IncomeTypeSelector from "./IncomeTypeSelector";

import { Context } from "../../../contexts/EasyFlowContext";
import { types } from "../../../hooks/reducers/useEasyFlowReducer";

const possibleApplicants = [
  "Primary applicant",
  "Secondary applicant",
  "3rd applicant",
  "4th applicant",
];

const IncomeTypeContainer = ({ keyName, allowNextStep, ...rest }) => {
  const {
    state: {
      data: { applicants_number },
    },
    dispatch,
  } = useContext(Context);
  const [applicantsData, setApplicantsData] = useState([]);
  const refData = useRef(applicantsData);

  const applicantsQuantity = parseInt(applicants_number[0].split(" ")[0]);
  const initialApplicants = possibleApplicants.slice(0, applicantsQuantity);

  const storeData = (i, value) => {
    if (value) {
      let tempData = [...applicantsData];

      if (tempData[i]) {
        tempData[i] = { ...value };
      } else {
        tempData.push(value);
      }

      setApplicantsData(tempData);
    }
  };

  const panes = () => {
    return initialApplicants.map((item, i) => ({
      key: i,
      menuItem: item,
      pane: (
        <Tab.Pane key={i}>
          <IncomeTypeSelector
            {...rest}
            savePanelData={(keyName, value) => storeData(i, keyName, value)}
            previousAnswer={rest.previousAnswer ? rest.previousAnswer[i] : null}
          />
        </Tab.Pane>
      ),
    }));
  };

  useEffect(() => {
    refData.current = applicantsData;

    if (applicantsData.length === applicantsQuantity) allowNextStep(true);
  }, [applicantsData]);

  useEffect(() => {
    return () =>
      dispatch({
        type: types.SAVE_DATA,
        payload: {
          keyName,
          value: refData.current,
        },
      });
  }, []);

  return (
    <Fragment>
      <Tab panes={panes()} renderActiveOnly={false} />
    </Fragment>
  );
};

export default IncomeTypeContainer;
