import React, { Fragment, useState, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import { ownershipOptions } from '../../../utils/selectOptions';
import { Accordion, Label, Icon } from 'semantic-ui-react';

const state = {
  data: {},
  errors: {}
};

const commonSchema = () => ({
  applicant: Joi.string().label('Type'),
  details: Joi.string().label('Details'),
  institution: Joi.string().label('Institution'),
  membership_number: Joi.number().label('Membership Number'),
  ownership: Joi.string().required().label('Ownership'),
  value: Joi.number().required().label('Value')
});

const schema = {
  home_contents: commonSchema(),
  superfunds: [],
  others: [
    commonSchema(),
    commonSchema()
  ]
};

//#region form-renders
const renderCommon = (activeIndex, setActiveIndex, handleChange, values, accumulatedLength, errors) => {
  const indexCount = accumulatedLength + 1;
  const items = ['Shares', 'Other Assets'];

  return items.map((item, index) => {
    return (
      <Fragment key={index + indexCount}>
        <Accordion.Title active={activeIndex === index + indexCount} index={index + indexCount} onClick={() => setActiveIndex(index + indexCount)}>
          <Label as='a' size={"large"}>
            <Icon name='edit' />
            {item}
          </Label>
        </Accordion.Title>

        <Accordion.Content active={activeIndex === index + indexCount}>
          <FormRow>
            <FormColumn>
              <CustomInput
                name={`others[${index}].details`}
                label={`${item} details`}
                value={values ? values[index]['details'] : null}
                error={errors[`others[${index}].details`]}
                handleChange={handleChange} />
            </FormColumn>

            <FormColumn>
              <CustomDropdown
                name={`others[${index}].ownership`}
                label='Ownership'
                options={ownershipOptions}
                value={values ? values[index]['ownership'] : null}
                error={errors[`others[${index}].ownership`]}
                handleChange={handleChange} />
            </FormColumn>

            <FormColumn>
              <CustomInput
                name={`others[${index}].value`}
                label='Value'
                value={values ? values[index]['value'] : null}
                error={errors[`others[${index}].value`]}
                handleChange={handleChange} />
            </FormColumn>
          </FormRow>
        </Accordion.Content>
      </Fragment>
    );
  });
}

const renderSuperfund = (activeIndex, setActiveIndex, handleChange, values, errors) => {
  let applicantsLabel = ['Primary', 'Secondary', '3rd', '4th'];
  applicantsLabel = applicantsLabel.slice(0, values.length);

  return applicantsLabel.map((item, index) => {
    return (
      <Fragment key={index + 1}>
        <Accordion.Title active={activeIndex === index + 1} index={index + 1} onClick={() => setActiveIndex(index + 1)}>
          <Label as='a' size={"large"}>
            <Icon name='edit' />
            {`Superfund (${item} Applicant)`}
          </Label>
        </Accordion.Title>

        <Accordion.Content active={activeIndex === index + 1}>
          <FormRow>
            <FormColumn>
              <CustomInput
                name={`superfunds[${index}].institution`}
                label='Institution'
                value={values ? values[index]['institution'] : null}
                error={errors[`superfunds[${index}].institution`]}
                handleChange={handleChange} />
            </FormColumn>

            <FormColumn>
              <CustomInput
                name={`superfunds[${index}].membership_number`}
                label='Membership Number'
                value={values ? values[index]['membership_number'] : null}
                error={errors[`superfunds[${index}].membership_number`]}
                handleChange={handleChange} />
            </FormColumn>

            <FormColumn>
              <CustomDropdown
                name={`superfunds[${index}].ownership`}
                label='Ownership'
                options={ownershipOptions}
                value={values ? values[index]['ownership'] : null}
                error={errors[`superfunds[${index}].ownership`]}
                handleChange={handleChange} />
            </FormColumn>

            <FormColumn>
              <CustomInput
                name={`superfunds[${index}].value`}
                label='Value'
                value={values ? values[index]['value'] : null}
                error={errors[`superfunds[${index}].value`]}
                handleChange={handleChange} />
            </FormColumn>
          </FormRow>
        </Accordion.Content>
      </Fragment>
    )
  });
}
//#endregion

const OtherAssetsForm = ({ handleChange, values, errors }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const { superfunds: superFundsCopy } = schema;

    for (let i = 0; i < values.superfunds.length; i++) {
      superFundsCopy.push(commonSchema());
    }

    schema.superfunds = [...superFundsCopy];
    state.data = values;
  }, [values]);

  return (
    <Accordion fluid styled>
      <Fragment key={0}>
        <Accordion.Title active={activeIndex === 0} onClick={() => setActiveIndex(0)} index={0}>
          <Label as='a' size={"large"}>
            <Icon name='edit' />
            {'Home Contents'}
          </Label>
        </Accordion.Title>

        <Accordion.Content active={activeIndex === 0}>
          <FormRow>
            <FormColumn>
              <CustomInput
                name='home_contents.details'
                label={`Assets details`}
                value={values ? values['home_contents']['details'] : null}
                error={errors['home_contents.details']}
                handleChange={handleChange} />
            </FormColumn>

            <FormColumn>
              <CustomDropdown
                name='home_contents.ownership'
                label='Ownership'
                options={ownershipOptions}
                value={values ? values['home_contents']['ownership'] : null}
                error={errors['home_contents.ownership']}
                handleChange={handleChange} />
            </FormColumn>

            <FormColumn>
              <CustomInput
                name='home_contents.value'
                label='Value'
                value={values ? values['home_contents']['value'] : null}
                error={errors['home_contents.value']}
                handleChange={handleChange} />
            </FormColumn>
          </FormRow>
        </Accordion.Content>
      </Fragment>

      {renderSuperfund(activeIndex, setActiveIndex, handleChange, values.superfunds, errors)}

      {renderCommon(activeIndex, setActiveIndex, handleChange, values.others, values.superfunds.length, errors)}
    </Accordion>
  );
}

export default withForm(OtherAssetsForm, state, schema);