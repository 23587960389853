import React, { useState, useEffect, useContext } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Grid, Divider, Segment } from 'semantic-ui-react';
import SelectionSummary from './SelectionSummary';
import DetailsForm from './DetailsForm';

import { Context } from '../../../contexts/EasyFlowContext';
import { types } from '../../../hooks/reducers/useEasyFlowReducer';

const BasicDetails = ({ keyName, allowNextStep }) => {
  const { state, dispatch } = useContext(Context);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isCaptchaReady, setIsCaptchaReady] = useState(false);

  const onCaptchaChange = value => {
    //console.log("Captcha value:", value);
    setIsCaptchaReady(true);
  }

  const onCaptchaExpired = () => {
    setIsCaptchaReady(false);
  }

  const onSubmitForm = (name, email, mobile) => {
    dispatch({
      type: types.SAVE_DATA,
      payload: {
        keyName,
        value: {
          name,
          email,
          mobile
        }
      }
    });
  }

  useEffect(() => {
    if (isFormReady && isCaptchaReady)
      allowNextStep(true);
    else
      allowNextStep(false);
  }, [isFormReady, isCaptchaReady]);

  let selections, basicDetails = null;
  if (state.data) {
    let tempData = { ...state.data };

    const { basic_details, ...nobasic_details } = tempData;
    selections = nobasic_details;
    basicDetails = basic_details;
  }

  return (
    <Segment placeholder style={{ width: '100%' }}>
      <Grid columns={2} relaxed='very' stackable>
        <Grid.Column>
          <SelectionSummary data={selections} />
        </Grid.Column>

        <Grid.Column>
          <DetailsForm submitForm={onSubmitForm} completeForm={() => { setIsFormReady(true); }} data={basicDetails} />
          <ReCAPTCHA
            sitekey="6LdLW6kZAAAAALhwkvgAbKkVWmVe0Lsj9pgrrTZM"
            onChange={onCaptchaChange}
            onExpired={onCaptchaExpired}
          />
        </Grid.Column>
      </Grid>

      <Divider vertical />
    </Segment>
  );
}

export default BasicDetails;