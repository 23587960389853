import React from 'react';

const style = {
  text: {
    margin: '0 0 0 50px',
    padding: '10px 25px',
    fontFamily: 'Poppins',
    color: 'rgb(163, 176, 189)',
    fontSize: '13px',
    letterSpacing: '1px',
    lineHeight: '25px'
  }
};

const AppFooter = () => {
  return (
    <footer>
      <pre style={style.text}>
        {`Christopher Borg is an authorised Credit Representative No
436774 of Mortgage Specialists Pty Ltd ACL Number is 387025
Copyright 2019 - Borg Financial`}
      </pre>
    </footer>
  );
}

export default AppFooter;