import React, { Fragment, useState, useEffect } from 'react';
import Joi from '@hapi/joi';
import { Accordion, Label, Icon } from 'semantic-ui-react';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import FormPairedInputs from '../../shared/form/FormPairedInputs';
import { frequencyOptions } from '../../../utils/selectOptions';

const state = {
  data: {},
  errors: {}
};

const schema = {
  gross_salary: {
    amount: Joi.number().required().label('Gross Salary'),
    frequency: Joi.string().required().label('Gross Salary Frequency')
  },
  allowance: {
    amount: Joi.number().required().label('Allowance'),
    frequency: Joi.string().required().label('Allowance Frequency')
  },
  bonus: {
    amount: Joi.number().required().label('Bonus'),
    frequency: Joi.string().required().label('Bonus Frequency')
  },
  commission: {
    amount: Joi.number().required().label('Commission'),
    frequency: Joi.string().required().label('Commission Frequency')
  },
  overtime_essential: {
    amount: Joi.number().required().label('Overtime Essential'),
    frequency: Joi.string().required().label('Overtime Essential Frequency')
  },
  overtime_non_essential: {
    amount: Joi.number().required().label('Overtime Non Essential'),
    frequency: Joi.string().required().label('Overtime Non Essential Frequency')
  },
  business_income: {
    last_tax_year: {
      amount: Joi.number().required().label('Last Tax Year'),
      profit_before_tax: Joi.number().required().label('Profit Before Tax'),
      depreciation: Joi.number().required().label('Depreciation'),
      addbacks: Joi.number().required().label('Addbacks')
    },
    previous_tax_year: {
      amount: Joi.number().required().label('Previous Tax Year'),
      profit_before_tax: Joi.number().required().label('Profit Before Tax'),
      depreciation: Joi.number().required().label('Depreciation'),
      addbacks: Joi.number().required().label('Addbacks')
    }
  },
  total_income: {
    total_payg_income: Joi.number().required().label('Total PAYG Income'),
    total_rental_income: Joi.number().required().label('Total Rental Income'),
    total_other_taxable_income: Joi.number().required().label('Total Other Taxable Income'),
    total_non_taxable_income: Joi.number().required().label('Total Non-Taxable Income')
  }
};

const wrappedForm = (handleChange, values, errors) => (
  <Fragment>
    <FormRow>
      <FormPairedInputs
        values={values}
        errors={errors}
        inputName='gross_salary'
        inputLabel='Gross Salary'
        selectOptions={frequencyOptions}
        handleChange={handleChange} />

      {/*<FormPairedInputs
        values={values}
        errors={errors}
        inputName='allowance'
        inputLabel='Allowance'
        selectOptions={frequencyOptions}
      handleChange={handleChange} />*/}
    </FormRow>

    {/*<FormRow>
      <FormPairedInputs
        values={values}
        errors={errors}
        inputName='bonus'
        inputLabel='Bonus'
        selectOptions={frequencyOptions}
        handleChange={handleChange} />

      <FormPairedInputs
        values={values}
        errors={errors}
        inputName='commission'
        inputLabel='Commission'
        selectOptions={frequencyOptions}
        handleChange={handleChange} />
    </FormRow>

    <FormRow>
      <FormPairedInputs
        values={values}
        errors={errors}
        inputName='overtime_essential'
        inputLabel='Overtime Essential'
        selectOptions={frequencyOptions}
        handleChange={handleChange} />

      <FormPairedInputs
        values={values}
        errors={errors}
        inputName='overtime_non_essential'
        inputLabel='Overtime Non Essential'
        selectOptions={frequencyOptions}
        handleChange={handleChange} />
    </FormRow>*/}
  </Fragment>
)

const IncomeForm = ({ handleChange, values, errors }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      {wrappedForm(handleChange, values, errors)}

      {/*<FormRow>
        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={() => setActiveIndex(0)}
          >
            <Label>
              <Icon name='edit' />
              {'Business Income'}
            </Label>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <FormRow>
              <FormColumn>
                <CustomInput
                  name='business_income.last_tax_year.amount'
                  label='Last Tax Year'
                  value={values ? values.business_income.last_tax_year.amount : null}
                  error={errors['business_income.last_tax_year.amount']}
                  handleChange={handleChange} />
              </FormColumn>
              <FormColumn>
                <CustomInput
                  name='business_income.last_tax_year.profit_before_tax'
                  label='Profit Before Tax'
                  value={values ? values.business_income.last_tax_year.profit_before_tax : null}
                  error={errors['business_income.last_tax_year.profit_before_tax']}
                  handleChange={handleChange} />
              </FormColumn>
              <FormColumn>
                <CustomInput
                  name='business_income.last_tax_year.depreciation'
                  label='Depreciation'
                  value={values ? values.business_income.last_tax_year.depreciation : null}
                  error={errors['business_income.last_tax_year.depreciation']}
                  handleChange={handleChange} />
              </FormColumn>
              <FormColumn>
                <CustomInput
                  name='business_income.last_tax_year.addbacks'
                  label='Addbacks'
                  value={values ? values.business_income.last_tax_year.addbacks : null}
                  error={errors['business_income.last_tax_year.addbacks']}
                  handleChange={handleChange} />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <CustomInput
                  name='business_income.previous_tax_year.amount'
                  label='Previous Tax Year'
                  value={values ? values.business_income.previous_tax_year.amount : null}
                  error={errors['business_income.previous_tax_year.amount']}
                  handleChange={handleChange} />
              </FormColumn>
              <FormColumn>
                <CustomInput
                  name='business_income.previous_tax_year.profit_before_tax'
                  label='Profit Before Tax'
                  value={values ? values.business_income.previous_tax_year.profit_before_tax : null}
                  error={errors['business_income.previous_tax_year.profit_before_tax']}
                  handleChange={handleChange} />
              </FormColumn>
              <FormColumn>
                <CustomInput
                  name='business_income.previous_tax_year.depreciation'
                  label='Depreciation'
                  value={values ? values.business_income.previous_tax_year.depreciation : null}
                  error={errors['business_income.previous_tax_year.depreciation']}
                  handleChange={handleChange} />
              </FormColumn>
              <FormColumn>
                <CustomInput
                  name='business_income.previous_tax_year.addbacks'
                  label='Addbacks'
                  value={values ? values.business_income.previous_tax_year.addbacks : null}
                  error={errors['business_income.previous_tax_year.addbacks']}
                  handleChange={handleChange} />
              </FormColumn>
            </FormRow>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={() => setActiveIndex(1)}
          >
            <Label>
              <Icon name='edit' />
              {'Total Income'}
            </Label>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <FormRow>
              <FormColumn>
                <CustomInput
                  name='total_income.total_payg_income'
                  label='Total PAYG Income'
                  value={values ? values.total_income.total_payg_income : null}
                  error={errors['total_income.total_payg_income']}
                  handleChange={handleChange} />
              </FormColumn>
              <FormColumn>
                <CustomInput
                  name='total_income.total_rental_income'
                  label='Total Rental Income'
                  value={values ? values.total_income.total_rental_income : null}
                  error={errors['total_income.total_rental_income']}
                  handleChange={handleChange} />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn>
                <CustomInput
                  name='total_income.total_other_taxable_income'
                  label='Total Other Taxable Income'
                  value={values ? values.total_income.total_other_taxable_income : null}
                  error={errors['total_income.total_other_taxable_income']}
                  handleChange={handleChange} />
              </FormColumn>
              <FormColumn>
                <CustomInput
                  name='total_income.total_non_taxable_income'
                  label='Total Non-Taxable Income'
                  value={values ? values.total_income.total_non_taxable_income : null}
                  error={errors['total_income.total_non_taxable_income']}
                  handleChange={handleChange} />
              </FormColumn>
            </FormRow>
          </Accordion.Content>
        </Accordion>
      </FormRow>*/}
    </Fragment>
  );
}

export default withForm(IncomeForm, state, schema);