import React, { useContext, useEffect } from "react";
import BasicStepper from "./shared/BasicStepper";

import { Context as EasyFlowContext } from "../contexts/EasyFlowContext";
import { types as easyFlowReducerTypes } from "../hooks/reducers/useEasyFlowReducer";

import { Context as AppContext } from "../contexts/AppContext";
import { types as appReducerTypes } from "../hooks/reducers/useAppReducer";

import {
  getInitSteps,
  getAssetFinancialSteps,
  getCommercialSteps,
  getResidentialSteps,
  getOtherSteps,
} from "./stepsManager";

const EasyFlow = () => {
  const { state, dispatch } = useContext(EasyFlowContext);
  const { dispatch: appDispatch } = useContext(AppContext);
  const { pathSteps } = state;

  //#region handlers
  const onSetStepperPath = (value) => {
    let newPath;

    switch (value) {
      case "Asset financial Personal Finance":
        newPath = getAssetFinancialSteps();
        break;

      case "Residential":
        newPath = getResidentialSteps();
        break;

      case "Commercial":
        newPath = getCommercialSteps();
        break;

      default:
        newPath = getOtherSteps();
        break;
    }

    dispatch({
      type: easyFlowReducerTypes.UPDATE_STEPPER_PATH,
      payload: newPath,
    });
  };

  const onMutateStepperPath = (activeStep, keyName, value) => {
    let stepsToRemove = [];

    switch (keyName) {
      case "asset_income_type":
        if (value !== "PAYG/Employed/(Payslips)")
          stepsToRemove.push("asset_employment_type");
        break;

      case "commercial_project":
        if (value !== "Development") stepsToRemove.push("construction_funding");
        else stepsToRemove.push("commercial_type");
        break;

      case "property_quantity":
        if (!(value > 1 && value < 4)) {
          stepsToRemove.push("total_lending_amount");
          stepsToRemove.push("estimated_market_value");
          stepsToRemove.push("current_rate");
        }
        break;

      case "residential_type":
        if (value === "Refinance") {
          stepsToRemove.push("project_type");
          stepsToRemove.push("smsf_type");
        } else {
          stepsToRemove.push("property_quantity");
          stepsToRemove.push("refinance_reason");
          stepsToRemove.push("total_lending_amount");
          stepsToRemove.push("estimated_market_value");
          stepsToRemove.push("current_rate");
        }
        break;

      case "project_type":
        if (value !== "SMSF") stepsToRemove.push("smsf_type");
        break;

      case "final_choise":
        if (value === "Request Call")
          stepsToRemove.push("authorize_information_usage");

        if (value === "Fast Track") stepsToRemove.push("select_broker");
        break;

      default:
        break;
    }

    if (stepsToRemove)
      dispatch({
        type: easyFlowReducerTypes.MUTATE_STEPPER_PATH,
        payload: {
          activeStep,
          stepsToRemove,
        },
      });
  };

  const onRecoverPathState = (activeStep) => {
    dispatch({
      type: easyFlowReducerTypes.RECOVER_PATH_STATE,
      payload: activeStep,
    });
  };

  const onSaveStepperData = (keyName, value) => {
    dispatch({
      type: easyFlowReducerTypes.SAVE_DATA,
      payload: {
        keyName,
        value,
      },
    });
  };

  const onFinishStepper = () => {
    appDispatch({
      type: appReducerTypes.SAVE_BASIC_DATA,
      payload: { ...state.data },
    });

    if (state.data["final_choise"][0] === "Fast Track")
      setTimeout(() => {
        appDispatch({
          type: appReducerTypes.IS_ADVANCED_INTERESTED,
        });
      }, 1500);
    else
      appDispatch({
        type: appReducerTypes.IS_FINISHED,
      });
  };

  const onResetStepper = () => {
    dispatch({
      type: easyFlowReducerTypes.RESET,
    });

    dispatch({
      type: easyFlowReducerTypes.SET_STEPPER_PATH,
      payload: getInitSteps(),
    });

    appDispatch({
      type: appReducerTypes.RESET,
    });
  };
  //#endregion

  //#region useEffect
  useEffect(() => {
    dispatch({
      type: easyFlowReducerTypes.SET_STEPPER_PATH,
      payload: getInitSteps(),
    });
  }, []);
  //#endregion useEffect

  return (
    <BasicStepper
      isResetable
      pathSteps={pathSteps}
      onSetPath={onSetStepperPath}
      onMutatePath={onMutateStepperPath}
      onRecoverPath={onRecoverPathState}
      onSaveData={onSaveStepperData}
      onResetStepper={onResetStepper}
      onFinishStepper={onFinishStepper}
    />
  );
};

export default EasyFlow;
