import React, { useState, useContext, Fragment } from 'react';
import EasyFlow from './EasyFlow';
import FactFind from './FactFind';

import { Context } from '../contexts/AppContext';
import EasyFlowContext from '../contexts/EasyFlowContext';

import GraphQLContaier from './GraphQLContainer';

const styles = {
  margin: 'auto',
  width: '80%'
};

const AppBody = () => {
  const [path, setPath] = useState(null);
  const { state: { isAdvancedInterested } } = useContext(Context);

  const basicPath = (
    <EasyFlowContext>
      <EasyFlow />
    </EasyFlowContext>
  );

  const advancedPath = <FactFind />;

  const components = isAdvancedInterested ? advancedPath : basicPath;

  const development = () => {
    if (!path)
      return (
        <Fragment>
          <button
            onClick={() => setPath('basicPath')}
          >
            Basic Path
          </button>

          <br />

          <button
            onClick={() => setPath('advancedPath')}
          >
            Advanced Path
        </button>
        </Fragment>
      );
    else if (path === 'basicPath')
      return basicPath;
    else
      return advancedPath;
  };

  return (
    <main style={styles} className='f-c-center'>
      <GraphQLContaier>
        {
          (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
            development() : components
        }
      </GraphQLContaier>
    </main>
  );
}

export default AppBody;