const getTitleOptions = () => {
  return [
    { key: 1, value: 'Mr', text: 'Mr' },
    { key: 2, value: 'Mrs', text: 'Mrs' },
    { key: 3, value: 'Ms', text: 'Ms' },
    { key: 4, value: 'Miss', text: 'Miss' },
    { key: 5, value: 'Dr', text: 'Dr' }
  ];
}
exports.titleOptions = getTitleOptions();

const getGenderOptions = () => {
  return [
    { key: 1, value: 'Male', text: 'Male' },
    { key: 2, value: 'Female', text: 'Female' },
    { key: 3, value: 'Other', text: 'Other' }
  ];
}
exports.genderOptions = getGenderOptions();

const getMaritalStatusOptions = () => {
  return [
    { key: 1, value: 'De Facto', text: 'De Facto' },
    { key: 2, value: 'Married', text: 'Married' },
    { key: 3, value: 'Divorced', text: 'Divorced' },
    { key: 4, value: 'Single', text: 'Single' },
    { key: 5, value: 'Other', text: 'Other' }
  ];
}
exports.maritalStatusOptions = getMaritalStatusOptions();

const getRelationshipOptions = () => {
  return [
    { key: 1, value: 'Father', text: 'Father' },
    { key: 2, value: 'Mother', text: 'Mother' },
    { key: 3, value: 'Brother', text: 'Brother' },
    { key: 4, value: 'Sister', text: 'Sister' },
    { key: 5, value: 'Son', text: 'Son' },
    { key: 6, value: 'Daughter', text: 'Daughter' },
    { key: 7, value: 'Cousin', text: 'Cousin' },
    { key: 8, value: 'Friend', text: 'Friend' },
    { key: 9, value: 'Mother-in-law', text: 'Mother-in-law' },
    { key: 10, value: 'Father-in-law', text: 'Father-in-law' },
    { key: 11, value: 'Brother-in-law', text: 'Brother-in-law' },
    { key: 12, value: 'Sister-in-law', text: 'Sister-in-law' },
    { key: 13, value: 'Uncle', text: 'Uncle' },
    { key: 14, value: 'Aunt', text: 'Aunt' },
    { key: 15, value: 'Boyfriend', text: 'Boyfriend' },
    { key: 16, value: 'Girlfriend', text: 'Girlfriend' },
    { key: 17, value: 'Spouse', text: 'Spouse' },
    { key: 18, value: 'Grandmother', text: 'Grandmother' },
    { key: 19, value: 'Grandfather', text: 'Grandfather' }
  ];
}
exports.relationshipOptions = getRelationshipOptions();

const getCountriesOptions = () => {
  return [
    { key: 1, value: 'Australia', text: 'Australia' },
    { key: 2, value: 'New Zealand', text: 'New Zealand' }
  ];
}
exports.countriesOptions = getCountriesOptions();

const getResidentialStatusOptions = () => {
  return [
    { key: 1, value: 'Citizen', text: 'Citizen' },
    { key: 2, value: 'Non-Resident', text: 'Non-Resident' },
    { key: 3, value: 'Permanent Resident', text: 'Permanent Resident' }
  ];
}
exports.residentialStatusOptions = getResidentialStatusOptions();

const getEmploymentTypeOptions = () => {
  return [
    { key: 1, value: 'Salaried Employee', text: 'Salaried Employee' },
    { key: 2, value: 'Self-Employed', text: 'Self-Employed' },
    { key: 3, value: 'Retired', text: 'Retired' },
    { key: 4, value: 'Unemployed', text: 'Unemployed' }
  ];
}
exports.employmentTypeOptions = getEmploymentTypeOptions();

const getEmploymentStatusOptions = () => {
  return [
    { key: 1, value: 'Primary Employment', text: 'Primary Employment' },
    { key: 2, value: 'Secondary Employment', text: 'Secondary Employment' }
  ];
}
exports.employmentStatusOptions = getEmploymentStatusOptions();

const getEmploymentBasisOptions = () => {
  return [
    { key: 1, value: 'Casual', text: 'Casual' },
    { key: 2, value: 'Contract', text: 'Contract' },
    { key: 3, value: 'Part-time', text: 'Part-time' },
    { key: 4, value: 'Full-time', text: 'Full-time' }
  ];
}
exports.employmentBasisOptions = getEmploymentBasisOptions();

const getPropertyTypeOptions = () => {
  return [
    { key: 1, value: 'Apartment / Unit / Flat', text: 'Apartment / Unit / Flat' },
    { key: 2, value: 'Company Title Unit', text: 'Company Title Unit' },
    { key: 3, value: 'Duplex', text: 'Duplex' },
    { key: 4, value: 'Fully Detached House', text: 'Fully Detached House' },
    { key: 5, value: 'Holiday Home', text: 'Holiday Home' },
    { key: 6, value: 'Holiday Rental', text: 'Holiday Rental' },
    { key: 7, value: 'House & Land', text: 'House & Land' },
    { key: 8, value: 'Licensed Builder Construction', text: 'Licensed Builder Construction' },
    { key: 9, value: 'Owner Builder Construction', text: 'Owner Builder Construction' },
    { key: 10, value: 'Semi Detached House', text: 'Semi Detached House' },
    { key: 11, value: 'Serviced Apartment', text: 'Serviced Apartment' },
    { key: 12, value: 'Single Bedroom < 50sqm', text: 'Single Bedroom < 50sqm' },
    { key: 13, value: 'Strata Title Unit', text: 'Strata Title Unit' },
    { key: 14, value: 'Townhouse', text: 'Townhouse' },
    { key: 15, value: 'Vacant Land', text: 'Vacant Land' },
    { key: 16, value: 'Villa', text: 'Villa' }
  ];
}
exports.propertyTypeOptions = getPropertyTypeOptions();

const getZoningOptions = () => {
  return [
    { key: 1, value: 'Commercial', text: 'Commercial' },
    { key: 2, value: 'Industrial', text: 'Industrial' },
    { key: 3, value: 'Residential', text: 'Residential' },
    { key: 4, value: 'Rural', text: 'Rural' }
  ];
}
exports.zoningOptions = getZoningOptions();

const getOwnershipOptions = () => {
  return [
    { key: 1, value: 'Applicant 1', text: 'Applicant 1' },
    { key: 2, value: 'Applicant 2', text: 'Applicant 2' },
    { key: 3, value: 'Joint', text: 'Joint' },
    { key: 4, value: 'Company / Trust', text: 'Company / Trust' },
    { key: 5, value: 'SMSF', text: 'SMSF' }
  ];
}
exports.ownershipOptions = getOwnershipOptions();

const getYesNoOptions = () => {
  return [
    { key: 1, value: 'Yes', text: 'Yes' },
    { key: 2, value: 'No', text: 'No' }
  ];
}
exports.yesNoOptions = getYesNoOptions();

const getVehicleTypeOptions = () => {
  return [
    { key: 1, value: 'Car', text: 'Car' },
    { key: 2, value: 'Motorcicle', text: 'Motorcicle' },
    { key: 3, value: 'Watercraft', text: 'Watercraft' },
    { key: 4, value: 'Bicycle', text: 'Bicycle' },
    { key: 5, value: 'Light Commercial', text: 'Light Commercial' },
    { key: 6, value: 'Trailer', text: 'Trailer' },
    { key: 7, value: 'Truck', text: 'Truck' },
    { key: 8, value: 'Bodies', text: 'Bodies' },
    { key: 9, value: 'Bus Coach or Special Purpose', text: 'Bus Coach or Special Purpose' },
    { key: 10, value: 'Caravan', text: 'Caravan' },
    { key: 11, value: 'Locomotive', text: 'Locomotive' },
    { key: 12, value: 'Other', text: 'Other' },
    { key: 13, value: 'Rail Cars', text: 'Rail Cars' }
  ];
}
exports.vehicleTypeOptions = getVehicleTypeOptions();

const getBankOptions = () => {
  return [
    { key: 1, value: 'AMP', text: 'AMP' },
    { key: 2, value: 'ANZ Bank', text: 'ANZ Bank' },
    { key: 3, value: 'Adelaide Bank', text: 'Adelaide Bank' },
    { key: 4, value: 'Bank of Melbourne', text: 'Bank of Melbourne' },
    { key: 5, value: 'Bank of Queensland', text: 'Bank of Queensland' },
    { key: 6, value: 'Bankwest', text: 'Bankwest' },
    { key: 7, value: 'CBA', text: 'CBA' },
    { key: 8, value: 'Citibank', text: 'Citibank' },
    { key: 9, value: 'Heritage', text: 'Heritage' },
    { key: 10, value: 'Homeloans LTD', text: 'Homeloans LTD' },
    { key: 11, value: 'ING', text: 'ING' },
    { key: 12, value: 'Keystart Home Loans', text: 'Keystart Home Loans' },
    { key: 13, value: 'Liberty', text: 'Liberty' },
    { key: 14, value: 'Me Bank', text: 'Me Bank' },
    { key: 15, value: 'MacQuarie', text: 'MacQuarie' },
    { key: 16, value: 'Mortgage Ezy', text: 'Mortgage Ezy' },
    { key: 17, value: 'NAB', text: 'NAB' },
    { key: 18, value: 'Pepper', text: 'Pepper' },
    { key: 19, value: 'Resimac', text: 'Resimac' },
    { key: 20, value: 'St George', text: 'St George' },
    { key: 21, value: 'Suncorp', text: 'Suncorp' },
    { key: 22, value: 'Teachers Mutual', text: 'Teachers Mutual' },
    { key: 23, value: 'Virgin Money', text: 'Virgin Money' },
    { key: 24, value: 'Westpac Bank', text: 'Westpac Bank' },
    { key: 25, value: 'AFG Home Loans', text: 'AFG Home Loans' },
    { key: 26, value: 'Astute', text: 'Astute' },
    { key: 27, value: 'Aussie Home Loans', text: 'Aussie Home Loans' },
    { key: 28, value: 'Australian First Mortgage', text: 'Australian First Mortgage' },
    { key: 29, value: 'Auswide Bank', text: 'Auswide Bank' },
    { key: 30, value: 'Bank Australia', text: 'Bank Australia' },
    { key: 31, value: 'Bank SA', text: 'Bank SA' },
    { key: 32, value: 'Bank of Sydney', text: 'Bank of Sydney' },
    { key: 33, value: 'Barnes Home Loans', text: 'Barnes Home Loans' },
    { key: 34, value: 'Bendigo Bank', text: 'Bendigo Bank' },
    { key: 35, value: 'Better Choice', text: 'Better Choice' },
    { key: 36, value: 'Beyond Bank Australia', text: 'Beyond Bank Australia' },
    { key: 37, value: 'Bluestone', text: 'Bluestone' },
    { key: 38, value: 'City Coast Credit Union', text: 'City Coast Credit Union' },
    { key: 39, value: 'Connective Home Loans', text: 'Connective Home Loans' },
    { key: 40, value: 'Credit Union Australia', text: 'Credit Union Australia' },
    { key: 41, value: 'Firstmac', text: 'Firstmac' },
    { key: 42, value: 'Future Financial', text: 'Future Financial' },
    { key: 43, value: 'HSBC', text: 'HSBC' },
    { key: 44, value: 'Homeside', text: 'Homeside' },
    { key: 45, value: 'IMB LTD', text: 'IMB LTD' },
    { key: 46, value: 'La Trobe', text: 'La Trobe' },
    { key: 47, value: 'Loan Market Go', text: 'Loan Market Go' },
    { key: 48, value: 'Loanave', text: 'Loanave' },
    { key: 49, value: 'Mortgage Mart', text: 'Mortgage Mart' },
    { key: 50, value: 'My Loan Elect', text: 'My Loan Elect' },
    { key: 51, value: 'Newcastle Permanent Building Society', text: 'Newcastle Permanent Building Society' },
    { key: 52, value: 'P&N Bank', text: 'P&N Bank' },
    { key: 53, value: 'Paramount', text: 'Paramount' },
    { key: 54, value: 'Quantas Credit Union', text: 'Quantas Credit Union' },
    { key: 55, value: 'Qudos Bank', text: 'Qudos Bank' },
    { key: 56, value: 'Rams', text: 'Rams' },
    { key: 57, value: 'Redzed', text: 'Redzed' },
    { key: 58, value: 'Resi', text: 'Resi' },
    { key: 59, value: 'SA Bank', text: 'SA Bank' },
    { key: 60, value: 'The Rock', text: 'The Rock' },
    { key: 61, value: 'VOW', text: 'VOW' },
    { key: 62, value: 'YBR Group Lending', text: 'YBR Group Lending' }
  ];
}
exports.bankOptions = getBankOptions();

const getMortgageTypeOptions = () => {
  return [
    { key: 1, value: 'Owner Occupied', text: 'Owner Occupied' },
    { key: 2, value: 'Investments', text: 'Investments' }
  ];
}
exports.mortgageTypeOptions = getMortgageTypeOptions();

const getRepaymentTypeOptions = () => {
  return [
    { key: 1, value: 'Principal & Interes', text: 'Principal & Interes' },
    { key: 2, value: 'Interest Only', text: 'Interest Only' }
  ];
}
exports.repaymentTypeOptions = getRepaymentTypeOptions();

const getCreditCardTypeOptions = () => {
  return [
    { key: 1, value: 'VISA', text: 'VISA' },
    { key: 2, value: 'MasterCard', text: 'MasterCard' },
    { key: 3, value: 'Amex', text: 'Amex' },
    { key: 4, value: 'Store Card', text: 'Store Card' },
    { key: 5, value: 'Other', text: 'Other' },
  ];
}
exports.creditCardTypeOptions = getCreditCardTypeOptions();

const getFrequencyOptions = () => {
  return [
    { key: 1, value: 'Weekly', text: 'Weekly' },
    { key: 2, value: 'Fortnightly', text: 'Fortnightly' },
    { key: 3, value: 'Monthly', text: 'Monthly' },
    { key: 4, value: 'Quarterly', text: 'Quarterly' },
    { key: 5, value: 'Annually', text: 'Annually' }
  ];
}
exports.frequencyOptions = getFrequencyOptions();

const getInsurerTypeOptions = () => {
  return [
    { key: 1, value: 'Home and Contents', text: 'Home and Contents' },
    { key: 2, value: 'Vehicle', text: 'Vehicle' },
    { key: 3, value: 'Private Health', text: 'Private Health' },
    { key: 4, value: 'Income Protection', text: 'Income Protection' },
    { key: 5, value: 'Life/TPD Provider', text: 'Life/TPD Provider' }
  ];
}
exports.insurerTypeOptions = getInsurerTypeOptions();

const getOtherAdvisersTypeOptions = () => {
  return [
    { key: 1, value: 'Accountant', text: 'Accountant' },
    { key: 2, value: 'Solicitor', text: 'Solicitor' },
    { key: 3, value: 'Financial Advisor', text: 'Financial Advisor' }
  ];
}
exports.otherAdvisersTypeOptions = getOtherAdvisersTypeOptions();

const getOtherAssetsTypeOptions = () => {
  return [
    { key: 1, value: 'Superfund', text: 'Superfund' },
    { key: 2, value: 'Shares', text: 'Shares' },
    { key: 3, value: 'Other Asset', text: 'Other Asset' }
  ];
}
exports.otherAssetsTypeOptions = getOtherAssetsTypeOptions();

const getOtherLoanOptions = () => {
  return [
    { key: 1, value: 'Personal Loan', text: 'Personal Loan' },
    { key: 2, value: 'SMSF', text: 'SMSF' },
    { key: 3, value: 'Student Loan', text: 'Student Loan' }
  ];
}
exports.otherLoanOptions = getOtherLoanOptions();