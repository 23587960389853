import React, { useState, useEffect, createContext } from "react";
import { Progress } from "semantic-ui-react";
import StepperButtons from "./StepperButtons";
import Step from "./Step";

import useArrayReducer, {
  types as arrayReducerTypes,
} from "../../hooks/reducers/useArrayReducer";

const styles = {
  container: {
    width: "90%",
  },
  progress_bar: {
    width: "90%",
    alignSelf: "center",
  },
};

const BasicStepper = ({
  pathSteps,
  isResetable = false,
  onSetPath = null,
  onMutatePath = null,
  onRecoverPath = null,
  onResetStepper,
  onFinishStepper = null,
  onSaveData = null,
}) => {
  const [arrayState, arrayDispatch] = useArrayReducer();
  const [activeStep, setActiveStep] = useState(0);
  const [allowNextStep, setAllowNextStep] = useState(false);

  const handleNextStep = () => {
    const { keyName } = pathSteps[activeStep].props;

    if (onSetPath && activeStep === 0) onSetPath(arrayState[0]);
    else if (onMutatePath && activeStep > 0)
      onMutatePath(activeStep, keyName, arrayState[0]);

    if (onSaveData && arrayState.length > 0) onSaveData(keyName, arrayState);

    setActiveStep((prevState) => {
      return prevState + 1;
    });
  };

  const handleBackStep = () => {
    onRecoverPath(activeStep - 1);

    setActiveStep((prevState) => {
      return prevState - 1;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setAllowNextStep(false);

    arrayDispatch({
      type: arrayReducerTypes.RESTART,
    });

    onResetStepper();
  };

  // useEffect for ArrayReducer update
  useEffect(() => {
    arrayDispatch({
      type: arrayReducerTypes.RESTART,
    });

    setAllowNextStep(false);

    if (onFinishStepper && activeStep + 1 === pathSteps.length) {
      onFinishStepper();
    }
  }, [activeStep]);

  const onFinishStep = (value) => {
    console.log(value);
  };

  const percent = pathSteps
    ? ((activeStep + 1) / (pathSteps.length <= 1 ? 10 : pathSteps.length)) * 100
    : 1;
  const component = pathSteps ? pathSteps[activeStep] : null;

  return (
    <div style={styles.container} className="f-c-center">
      <Progress percent={percent} style={styles.progress_bar} active success />

      <ArrayContext.Provider value={{ arrayState, arrayDispatch }}>
        {component && (
          <Step keyName={component.props.keyName} onFinish={onFinishStep}>
            {React.cloneElement(component, {
              allowNextStep: component.props.allowNextStep
                ? (value) => setAllowNextStep(value)
                : null,
            })}
          </Step>
        )}
      </ArrayContext.Provider>

      {(activeStep < 1 || activeStep + 1 !== pathSteps.length) && (
        <StepperButtons
          activeStep={activeStep}
          isResetable={isResetable}
          selectionCount={arrayState.length}
          isNextStepAllowed={allowNextStep}
          handleBackStep={handleBackStep}
          handleNextStep={handleNextStep}
          handleReset={handleReset}
        />
      )}
    </div>
  );
};

export const ArrayContext = createContext();
export default BasicStepper;
