import React, { Fragment } from 'react';
import useStatefulStep from '../../../hooks/useStatefulStep';
import CustomInput from '../../shared/controls/CustomInput';

const TotalLendingAmount = ({ keyName, allowNextStep }) => {
  const [value, setValue] = useStatefulStep(keyName, allowNextStep);

  return (
    <Fragment>
      <h1>Current total lending amount</h1>
      <p>Please select...</p>

      <CustomInput
        name='amount'
        label='Current amount'
        value={value}
        handleChange={({ currentTarget: { name, value } }) => setValue(value)}
      />
    </Fragment>
  );
}

export default TotalLendingAmount;