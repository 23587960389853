import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'semantic-ui-react';
import CustomInput from '../../shared/controls/CustomInput';

const DetailsForm = ({ submitForm, completeForm, data }) => {
  const [name, setName] = useState(data?.name ?? '');
  const [email, setEmail] = useState(data?.email ?? '');
  const [mobile, setMobile] = useState(data?.mobile ?? '');

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      if (name && email && mobile)
        completeForm();

      return;
    }

    if (name && email && mobile) {
      submitForm(name, email, mobile);
      completeForm();
    }
  }, [name, email, mobile]);

  return (
    <Form>
      <CustomInput
        name='Name'
        label='Your Name'
        value={name}
        handleChange={({ currentTarget: { name, value } }) => setName(value)}
      />

      <CustomInput
        name='Email'
        label='Your Email'
        value={email}
        handleChange={({ currentTarget: { name, value } }) => setEmail(value)}
      />

      <CustomInput
        name='Phone Number'
        label='Your Phone Number'
        value={mobile}
        handleChange={({ currentTarget: { name, value } }) => setMobile(value)}
      />
    </Form>
  );
};

export default DetailsForm;