import { useReducer } from 'react';

export const types = {
  NEXT_STEP: 0,
  COMPLETE_STEP: 1,
  PREVIOUS_STEP: 2,
  STEP_CLICK: 3
};

let initState = {
  activeStep: 0,
  completedSteps: []
};

const reducer = (state, { type, payload }) => {
  const { activeStep, completedSteps } = state;

  switch (type) {
    case types.NEXT_STEP:
      return {
        ...state,
        activeStep: activeStep + 1
      };

    case types.COMPLETE_STEP:
      return {
        ...state,
        completedSteps: [
          ...completedSteps,
          activeStep
        ]
      };

    case types.PREVIOUS_STEP:
      return {
        ...state,
        activeStep: activeStep - 1
      };

    case types.STEP_CLICK:
      return {
        ...state,
        activeStep: payload
      };

    default:
      return state;
  }
}

const useStepperReducer = () => useReducer(reducer, initState);

export default useStepperReducer;