import React, { StrictMode } from 'react';
import AppContext from '../contexts/AppContext';

const style = {
  display: 'flex',
  flexDirection: 'column'
};

const AppLayout = (props) => {
  return (
    <div className="app" style={style}>
      {/*<StrictMode>*/}
      <AppContext>
        {props.children}
      </AppContext>
      {/*</StrictMode>*/}
    </div>
  );
}

export default AppLayout;