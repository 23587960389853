import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import {
  propertyTypeOptions,
  zoningOptions,
  ownershipOptions,
  yesNoOptions
} from '../../../utils/selectOptions';

const state = {
  data: {},
  errors: {}
};

const schema = {
  address: Joi.string().required().label('Investment 1 Property Address'),
  value: Joi.number().required().label('Value'),
  montlhy_rental_income: Joi.number().label('Monthly Rental Income'),
  type: Joi.string().required().label('Property Type'),
  zoning: Joi.string().required().label('Zoning'),
  ownership: Joi.string().required().label('Ownership'),
  to_be_sold: Joi.boolean().label('To be sold?'),
  will_it_become_investment_after_settlement: Joi.boolean().required().label('Will it become investment after settlement?')
};

const InvestmentPropertyDetailsForm = ({ handleChange, values, errors, ...rest }) => {
  const { investmentsLength } = rest;

  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomInput
            name='address'
            label={`Investment ${investmentsLength} Property Address`}
            value={values ? values['address'] : null}
            error={errors['address']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='value'
            label='Value'
            value={values ? values['value'] : null}
            error={errors['value']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomInput
            name='montlhy_rental_income'
            label='Monthly Rental Income'
            value={values ? values['montlhy_rental_income'] : null}
            error={errors['montlhy_rental_income']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='type'
            label='Property Type'
            options={propertyTypeOptions}
            value={values ? values['type'] : null}
            error={errors['type']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='zoning'
            label='Zoning'
            options={zoningOptions}
            value={values ? values['zoning'] : null}
            error={errors['zoning']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='ownership'
            label='Ownership'
            options={ownershipOptions}
            value={values ? values['ownership'] : null}
            error={errors['ownership']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='to_be_sold'
            label='To be sold?'
            options={yesNoOptions}
            value={values ? values['to_be_sold'] : null}
            error={errors['to_be_sold']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomDropdown
            name='will_it_become_investment_after_settlement'
            label='Will it become investment after settlement?'
            options={yesNoOptions}
            value={values ? values['will_it_become_investment_after_settlement'] : null}
            error={errors['will_it_become_investment_after_settlement']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>
    </Fragment>
  );
}

export default withForm(InvestmentPropertyDetailsForm, state, schema);