import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';

const state = {
  data: {
    mobile_phone: '',
    home_phone: '',
    work_phone: '',
    primary_email: '',
    secondary_email: '',
    website: '',
    preferred_contact_method: []
  },
  errors: {}
};

const schema = {
  mobile_phone: Joi.number().required().label('Mobile Phone'),
  home_phone: Joi.number().allow('').label('Home Phone'),
  primary_email: Joi.string().email({ tlds: { allow: ['com'] } }).max(50).required().label('Primary Email')
};

const ContactDetailsForm = ({ handleChange, values, errors }) => {
  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomInput
            name='mobile_phone'
            label='Mobile Phone'
            value={values ? values['mobile_phone'] : null}
            error={errors['mobile_phone']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='home_phone'
            label='Home Phone'
            value={values ? values['home_phone'] : null}
            error={errors['home_phone']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='primary_email'
            label='Primary Email'
            value={values ? values['primary_email'] : null}
            error={errors['primary_email']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>
    </Fragment>
  );
}

export default withForm(ContactDetailsForm, state, schema);