import React, { useState, useEffect } from "react";

import Title from "./Title";
import Description from "./Description";
import Tile from "./Tile";
import TileSelector from "./TileSelector";

/* import { ArrayContext } from "./BasicStepper";
import useArrayReducer, { types } from "../../hooks/reducers/useArrayReducer"; */

import useStepPreviousAnswer from "../../hooks/useStepPreviousAnswer";

const Step = ({ title, description, keyName, children, onFinish }) => {
  const { getValue } = useStepPreviousAnswer();
  const [count, setCount] = useState(0);
  const [previousAnswer, setPreviousAnswer] = useState(null);

  useEffect(() => {
    onFinish(count);

    setCount((prevState) => ++prevState);
    setPreviousAnswer(getValue(keyName));
  }, [children]);

  return (
    /*   <Fragment>
      <Title>{title}</Title>
      <Description>{description}</Description>

      <TileSelector>
        { */
    previousAnswer
      ? React.cloneElement(children, {
          previousAnswer: previousAnswer,
        })
      : children
    /*}
      {/* </TileSelector>
    </Fragment> */ //}
  );
};

export default Step;
