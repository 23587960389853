import React, { useEffect, useContext } from "react";
import * as ReactIs from "react-is";
import HorizontalList from "./HorizontalList";

import { ArrayContext } from "./BasicStepper";
import { types } from "../../hooks/reducers/useArrayReducer";

const TileSelector = ({ children, multiple = false, ...rest }) => {
  const { arrayState: state, arrayDispatch: dispatch } = useContext(
    ArrayContext
  );

  const handleTileSelect = (value) => {
    if (!multiple) {
      dispatch({
        type: types.REPLACE_ITEM,
        payload: value,
      });
    } else {
      if (state.length === 0) {
        dispatch({
          type: types.INSERT_ITEM,
          payload: value,
        });
      } else {
        if (state.indexOf(value) === -1) {
          dispatch({
            type: types.INSERT_ITEM,
            payload: value,
          });
        } else {
          dispatch({
            type: types.REMOVE_ITEM,
            payload: value,
          });
        }
      }
    }
  };

  const isSelected = (value) => {
    const isSelected = state.indexOf(value) === -1 ? false : true;
    return isSelected;
  };

  const handleSelectAction = (selector = null, handler = null, param) => {
    if (selector) {
      selector(param);
    }

    if (handler) {
      handler(param);
    }
  };

  useEffect(() => {
    if (rest.previousAnswer) {
      dispatch({
        type: types.SET_ITEM,
        payload: rest.previousAnswer,
      });
    }
  }, [rest.previousAnswer]);

  return (
    <HorizontalList>
      {React.Children.map(children, (child, key) => {
        if (ReactIs.isFragment(child)) {
          return React.Children.map(
            child.props.children,
            (subChild, subKey) => {
              return React.cloneElement(subChild, {
                key: subKey,
                isSelected: isSelected(subChild.props.value),
                onSelect: (param) =>
                  handleSelectAction(
                    subChild.props.onSelect,
                    handleTileSelect,
                    param
                  ),
              });
            }
          );
        } else if (child) {
          return React.cloneElement(child, {
            key,
            isSelected: isSelected(child.props.value),
            onSelect: (param) =>
              handleSelectAction(child.props.onSelect, handleTileSelect, param),
          });
        }
      })}
    </HorizontalList>
  );
};

export default TileSelector;
