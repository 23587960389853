import React, { useState } from 'react';
import { Input, Label } from 'semantic-ui-react';

const CustomInput = ({ name, label, type = 'text', handleChange, error, value, ...rest }) => {
  const [stateValue, setstateValue] = useState(value ?? '');

  const onHandleBlur = (item) => {
    handleChange(item);
  }

  return (
    <div className='form-group'>
      <Label pointing='below' prompt>{label}</Label>
      <Input
        fluid
        placeholder={label}
        type={type}
        value={stateValue}
        onBlur={({ currentTarget: { value } }) => onHandleBlur({ currentTarget: { name, value } })}
        onChange={(e, { value }) => setstateValue(value)}
        {...rest}
      />
      {/*error && <span className='alert alert-danger'>{error}</span>*/}
    </div>
  );
}

export default CustomInput;