import { useContext } from "react";

import { Context } from "../contexts/EasyFlowContext";

const useStepPreviousAnswer = () => {
  const {
    state: { data },
  } = useContext(Context);

  const getValue = (keyName) => {
    return data[keyName];
  };

  return { getValue };
};

export default useStepPreviousAnswer;
