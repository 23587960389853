import React, { Fragment, useContext } from 'react';
import { Container } from 'semantic-ui-react';

import CustomLoader from './Loader';

import { Context as AppContext } from '../../contexts/AppContext';
import { types } from '../../hooks/reducers/useAppReducer';
import { useEffect } from 'react';

const FinishApplication = () => {
  const { state: { isFinished }, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (!isFinished) {
      setTimeout(() => {
        dispatch({
          type: types.IS_ADVANCED_INTERESTED
        });
      }, 1000);
    }
  }, []);

  let components = null;
  if (isFinished) {
    components = (
      <Fragment>
        <h2>
          Thank you for expresing your interest.
        </h2>

        <h2>
          One of our team members will soon get in touch with you.
        </h2>
      </Fragment>
    );
  } else {
    components = (
      <Fragment>
        <h2>
          Thank you for expresing your interest.
        </h2>

        <h2>
          Please wait while you're beign redirected to the FactFind form
        </h2>

        <CustomLoader />
      </Fragment>
    );
  }

  return (
    <Container text textAlign='center'>
      {components}
    </Container>
  );
}

export default FinishApplication;