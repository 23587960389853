import React, { Fragment } from 'react';
import FormColumn from './FormColumn';
import CustomInput from '../controls/CustomInput';
import CustomDropdown from '../controls/CustomDropdown';

const FormPairedInputs = ({ handleChange, values, errors, inputName, inputLabel, selectOptions }) => (
  <Fragment>
    <FormColumn>
      <CustomInput
        name={`${inputName}.amount`}
        label={inputLabel}
        value={values ? values[inputName].amount : null}
        error={errors[`${inputName}.amount`]}
        handleChange={handleChange} />
    </FormColumn>
    <FormColumn>
      <CustomDropdown
        name={`${inputName}.frequency`}
        label='Income Frequency'
        options={selectOptions}
        value={values ? values[inputName].frequency : null}
        error={errors[`${inputName}.frequency`]}
        handleChange={handleChange} />
    </FormColumn>
  </Fragment>
);

export default FormPairedInputs;