import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const SMSFType = props => (
  <Fragment>
    <TileSelector {...props}>
      <Tile value='Residential' />

      <Tile value='Commercial' />
    </TileSelector>
  </Fragment>
);

export default SMSFType;