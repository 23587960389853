import React, { Fragment } from 'react';
import useStatefulStep from '../../../hooks/useStatefulStep';
import CustomInput from '../../shared/controls/CustomInput';

const EstimatedMarketValue = ({ keyName, allowNextStep }) => {
  const [value, setValue] = useStatefulStep(keyName, allowNextStep);

  return (
    <Fragment>
      <h1>Estimated market value</h1>
      <p>Please enter the estimated value of all properties...</p>

      <CustomInput
        name='amount'
        label='Estimated value'
        value={value}
        handleChange={({ currentTarget: { name, value } }) => setValue(value)}
      />
    </Fragment>
  );
}

export default EstimatedMarketValue;