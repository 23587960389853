import React from 'react';
import CustomButton from './controls/CustomButton';
import FormCenteredContainer from './form/FormCenteredContainer';

const StepperButtons = ({ activeStep, isResetable, selectionCount, isNextStepAllowed, ...handlers }) => {
  return (
    <FormCenteredContainer>
      {activeStep !== 0 &&
        <CustomButton
          label={'Back'}
          onClick={() => handlers.handleBackStep()} />
      }

      <CustomButton
        label={'Next'}
        disabled={selectionCount === 0 && !isNextStepAllowed}
        onClick={() => handlers.handleNextStep()} />

      {isResetable &&
        <CustomButton
          label={'Reset'}
          disabled={true} // TODO: DEV ONLY
          onClick={() => handlers.handleReset()} />
      }
    </FormCenteredContainer>
  );
}

export default StepperButtons;