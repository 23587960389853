import React, { useState } from 'react';
import Joi from '@hapi/joi';

const withForm = (WrappedForm, stateParam, schemaParam) => {
  const Form = (props) => {
    const [state] = useState(stateParam);
    const { errors } = state;
    const { isMainProp, values, index, section, propKeyName, appDispatch } = props;

    const validate = () => {
      const schema = Joi.object(schemaParam);
      const { error } = schema.validate(state.data);
      if (!error) return null;

      const errors = {};
      for (let item of error.details) errors[item.path[0]] = item.message;
      return errors;
    }

    const validateProperty = ({ name, value }) => {
      let namePath, baseSchema;

      // TODO: Not the ideal solution for obtaining an element from an object - Refactor: Use a more generic approach (REGEX?)
      if (name.indexOf('[') !== -1) {
        namePath = name.split('[');

        namePath = [
          namePath[0],
          ...namePath[1].split(']')
        ];

        baseSchema = schemaParam[namePath[0]][namePath[1]][namePath[2].replace('.', '')]
      } else {
        namePath = name.split('.');
        baseSchema = namePath.length === 1 ? schemaParam[name] : schemaParam[namePath[0]][namePath[1]];
      }
      // TODO END

      const obj = { [name]: value };
      const schema = Joi.object({ [name]: baseSchema });
      const { error } = schema.validate(obj);

      return error ? error.details[0].message : null;
    }

    const handleChange = ({ currentTarget: input }) => {
      const errors = { ...state.errors };
      const errorMessage = validateProperty(input);

      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];

      // DONE: Refactored with useAppReducer Hook

      if (index || index === 0) {
        const path = isMainProp ?
          `${section}[${index}].${propKeyName}.${input.name}` :
          `${section}.${propKeyName}[${index}].${input.name}`;

        appDispatch({
          type: 'SAVE_ADVANCED_DATA',
          payload: {
            path: path,
            value: input.value
          }
        });
      }
      else
        appDispatch({
          type: 'SAVE_ADVANCED_DATA',
          payload: {
            path: `${section}.${propKeyName}.${input.name}`,
            value: input.value
          }
        });
    }

    // Method to remove null or empty objects (Not yet referenced in the code)
    const objRemoveEmptyOrNull = obj => {
      Object.keys(obj).forEach(k =>
        (obj[k] && typeof obj[k] === 'object' && this.objRemoveEmptyOrNull(obj[k])) ||
        (!obj[k] && obj[k] !== undefined && delete obj[k])
      );

      return obj;
    }

    return (
      <form>
        <WrappedForm
          handleChange={handleChange}
          values={values}
          errors={errors} />
      </form>
    );
  }

  return Form;
}

export default withForm;