import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import { bankOptions, ownershipOptions } from '../../../utils/selectOptions';

const state = {
  data: {},
  errors: {}
};

const schema = {
  bank: Joi.string().max(50).required().label('Bank'),
  bsb: Joi.string().max(50).required().label('BSB'),
  account_number: Joi.string().max(50).required().label('Account Number'),
  ownership: Joi.string().max(50).required().label('Ownership'),
  value: Joi.number().required().label('Value')
};

const BankAccountsForm = ({ handleChange, values, errors }) => {
  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='bank'
            label='Bank'
            options={bankOptions}
            value={values ? values['bank'] : null}
            error={errors['bank']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomInput
            name='bsb'
            label='BSB'
            value={values ? values['bsb'] : null}
            error={errors['bsb']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomInput
            name='account_number'
            label='Account Number'
            value={values ? values['account_number'] : null}
            error={errors['account_number']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomDropdown
            name='ownership'
            label='Ownership'
            options={ownershipOptions}
            value={values ? values['ownership'] : null}
            error={errors['ownership']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomInput
            name='value'
            label='Value'
            value={values ? values['value'] : null}
            error={errors['value']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>
    </Fragment>
  );
}

export default withForm(BankAccountsForm, state, schema);