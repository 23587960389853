import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const CommercialProject = props => {

  return (
    <Fragment>
      <h1>Title...</h1>
      <p>Text...</p>

      <TileSelector {...props}>
        <Tile value='Purchase' />

        <Tile value='Refinance' />

        <Tile value='Development' />
      </TileSelector>
    </Fragment>
  );
}

export default CommercialProject;