import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const RefinanceReason = (props) => (
  <Fragment>
    <p>What is the reason refinancing?</p>

    <TileSelector {...props}>
      <Tile value='Better Rate' />

      <Tile value='Consolidate Debt' />

      <Tile value='Equity Release' />
    </TileSelector>
  </Fragment>
);

export default RefinanceReason;