import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const AssetFinance = props => {
  return (
    <Fragment>
      <h1>Some title</h1>
      <p>Some text</p>

      <TileSelector {...props}>
        <Tile value="Equipment Plan" />

        <Tile value="Vehicle Purchase" />

        <Tile value="Personal Loan Credit Cards" />

        <Tile value="Cashflow Lending" />
      </TileSelector>
    </Fragment>
  );
}

export default AssetFinance;