import React, { Fragment } from 'react';
import { Label, Icon, Accordion } from 'semantic-ui-react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormCenteredContainer from '../shared/form/FormCenteredContainer';

const possibleApplicants = [
  'Primary Applicant',
  'Secondary Applicant',
  '3rd Applicant',
  '4th Applicant'
];

const AccordionMapper = ({ isAdditionable = false, formComponent: FormComponent, baseObject, propKeyName, types, ...rest }) => {
  const onAddItemClick = () => {
    const { section, appDispatch } = rest;

    appDispatch({
      type: types.ADD_ITEM_TO_ARRAY,
      payload: `${section}.${propKeyName}`
    });
  }

  const panels = () => {
    let smallerArray = [];

    if (!isAdditionable) {
      smallerArray = baseObject.map(item => {
        return item[propKeyName];
      });
    } else {
      smallerArray = [...baseObject];
    }

    return smallerArray.map((item, index) => {
      return ({
        key: `panel-${index}`,
        title: {
          content: <Label as='a' size={"medium"}>
            <Icon name='edit' />
            {possibleApplicants[index]}
          </Label>,
        },
        content: {
          content: (
            <FormComponent
              index={index}
              values={item}
              propKeyName={propKeyName}
              {...rest} />
          )
        }
      });
    });
  }

  return (
    <Fragment>
      <Accordion fluid styled defaultActiveIndex={0} panels={panels()} />
      {isAdditionable &&
        <FormCenteredContainer>
          <AddCircleIcon className="button" onClick={onAddItemClick} />
        </FormCenteredContainer>
      }
    </Fragment>
  );
}

export default AccordionMapper;