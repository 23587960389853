import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';

import CustomButton from './controls/CustomButton';
import FormCenteredContainer from './form/FormCenteredContainer';

import useStepperReducer, { types } from '../../hooks/reducers/useStepperReducer';

//#region Horizontal Stepper Styles
const styles = {
  stepContent: {
    margin: '25px 0'
  }
};

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon({ active, completed }) {
  const classes = useQontoStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};
//#endregion Horizontal Stepper

//#region Vertical Stepper Styles
//#endregion Vertical Stepper Styles

const AdvancedStepper = ({ isVertical = false, mainDispatch = null, steps, getStepContent, children }) => {
  const [{ activeStep, completedSteps }, dispatch] = useStepperReducer();

  //#region EventHandlers
  const handleStepClick = step => {
    dispatch({
      type: types.STEP_CLICK,
      payload: step
    });
  }

  const handleNextClick = () => {
    // TODO: IF FORM IS VALIDATED AND COMPLETE, THEN
    if (!_.find(completedSteps, step => { return step === activeStep }))
      dispatch({
        type: types.COMPLETE_STEP
      });
    // TODO: ELSE, DISPATCH ACTION TO ADD THE STEP TO 'INCOMPLETE ARRAY'

    dispatch({
      type: types.NEXT_STEP
    });
  }

  const handleBackClick = () => {
    dispatch({
      type: types.PREVIOUS_STEP
    });
  }

  const handleFinishSection = () => {
    mainDispatch({
      type: types.COMPLETE_STEP
    });

    mainDispatch({
      type: types.NEXT_STEP
    });
  }
  //#endregion EventHandlers

  //#region Loaders
  const loadVerticalStepper = () => {
    return (
      <div className={'root'}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                completed={index in completedSteps}
                style={{ cursor: 'pointer', width: '10%' }}
                onClick={() => handleStepClick(index)}>
                {label}
              </StepLabel>
              <StepContent>
                {getStepContent(index, dispatch)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={'resetContainer'}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            {children}
          </Paper>
        )}
      </div>
    );
  }

  const loadHorizontalStepper = () => {
    return (
      <Fragment>
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={QontoStepIcon}
                completed={_.includes(completedSteps, index)}
                style={{ cursor: 'pointer' }}
                onClick={() => handleStepClick(index)}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <FormCenteredContainer>
          <h4>
            {steps[activeStep]}
          </h4>
        </FormCenteredContainer>
        <div style={styles.stepContent}>
          {getStepContent(activeStep)}
        </div>
      </Fragment>
    );
  }
  //#endregion Loaders

  return (
    <Fragment>
      {isVertical ?
        loadVerticalStepper() :
        loadHorizontalStepper()
      }
      {!isVertical &&
        <FormCenteredContainer>
          {activeStep !== 0 &&
            <CustomButton
              label={'Back'}
              onClick={handleBackClick}
            />
          }
          {steps[activeStep] === steps[steps.length - 1] ?
            <CustomButton
              label={'Finish section'}
              onClick={handleFinishSection}
            /> :
            <CustomButton
              label={'Next'}
              onClick={handleNextClick}
            />
          }
        </FormCenteredContainer>
      }
    </Fragment>
  );
}

export default AdvancedStepper;