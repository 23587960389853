import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const ResidentialType = props => {
  return (
    <Fragment>
      <p>Select the type</p>
      <TileSelector {...props}>
        <Tile value='Refinance' />

        <Tile value='First Home Buyer/Owner Occupier Purchase' />

        <Tile value='Investor' />

        <Tile value='Retirement' />
      </TileSelector>
    </Fragment>
  );
}

export default ResidentialType;