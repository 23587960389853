import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import CustomCheckBox from '../../shared/controls/CustomCheckBox';
import { bankOptions, creditCardTypeOptions } from '../../../utils/selectOptions';

const state = {
  data: {},
  errors: {}
};

const schema = {
  lender: Joi.string().label(`Lender`),
  limit: Joi.number().label('Limit'),
  balance: Joi.number().label('Balance'),
  /* credit_card_type: Joi.string().label('Credit Card Type'),
  credit_card_number: Joi.number().label('Credit Card Number'),
  refinance: Joi.boolean().label('Refincance?') */
};

const CreditCardsForm = ({ handleChange, values, errors, creditCardLength }) => {
  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomDropdown
            name='lender'
            label={`Credit Card ${creditCardLength} - Lender`}
            options={bankOptions}
            value={values ? values['lender'] : null}
            error={errors['lender']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='limit'
            label='Limit'
            value={values ? values['limit'] : null}
            error={errors['limit']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='balance'
            label='Balance'
            value={values ? values['balance'] : null}
            error={errors['balance']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      {/* <FormRow>
        <FormColumn>
          <CustomDropdown
            name='credit_card_type'
            label='Credit Card Type'
            options={creditCardTypeOptions}
            value={values ? values['credit_card_type'] : null}
            error={errors['credit_card_type']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomInput
            name='credit_card_number'
            label='Credit Card Number'
            value={values ? values['credit_card_number'] : null}
            error={errors['credit_card_number']}
            handleChange={handleChange} />
        </FormColumn>
        <FormColumn>
          <CustomCheckBox
            name='refinance'
            label='Refinance?'
            checked={values ? values['refinance'] : null}
            error={errors['refinance']}
            handleChange={handleChange}
          />
        </FormColumn>
      </FormRow> */}
    </Fragment>
  );
}

export default withForm(CreditCardsForm, state, schema);