import React, { createContext } from 'react';
import useAppReducer from '../hooks/reducers/useAppReducer';

export const Context = createContext();

const AppContext = ({ children }) => {
  const [state, dispatch] = useAppReducer();

  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  );
}

export default AppContext;