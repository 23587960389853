import React, { Fragment } from 'react';
import useStatefulStep from '../../../hooks/useStatefulStep';
import CustomInput from '../../shared/controls/CustomInput';

const CurrentRate = ({ keyName, allowNextStep }) => {
  const [value, setValue] = useStatefulStep(keyName, allowNextStep);

  return (
    <Fragment>
      <h1></h1>
      <p></p>

      <CustomInput
        name='rate'
        label='Current rate (%)'
        value={value}
        handleChange={({ currentTarget: { name, value } }) => setValue(value)}
      />
    </Fragment>
  );
}

export default CurrentRate;