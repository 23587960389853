import React from 'react';
import AppLayout from './components/AppLayout';
import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';
import AppBody from './components/AppBody';

import './App.css';

const App = () => {
  return (
    <AppLayout>
      <AppHeader />
      <AppBody />
      <AppFooter />
    </AppLayout>
  );
}

export default App;