import React, { Fragment } from "react";
import TileSelector from "./TileSelector";
import Tile from "./Tile";

const FinalChoise = (props) => {
  return (
    <Fragment>
      <p>What next?</p>

      <TileSelector {...props}>
        <Tile value="Request Call" />

        <Tile value="Fast Track" />
      </TileSelector>
    </Fragment>
  );
};

export default FinalChoise;
