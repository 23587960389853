import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const PropertyQuantity = props => {
  return (
    <Fragment>
      <h1>How many properties?</h1>
      <p>Please select...</p>

      <TileSelector {...props}>
        <Tile value='1' />

        <Tile value='2' />

        <Tile value='3' />

        <Tile value='4+' />
      </TileSelector>
    </Fragment>
  );
}

export default PropertyQuantity;