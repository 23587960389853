import React from 'react';
import Logo from '../logo_borgfinancial.png';

const AppHeader = () => {
  return (
    <header>
      <img src={Logo} alt="BorgFinancial Logo"></img>
      {React.version}
    </header>
  );
}

export default AppHeader;