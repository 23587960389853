import React, { useContext } from 'react';
/*import FoodExpensesForm from './expenses/FoodExpensesForm';
import DiscretionaryExpensesForm from './expenses/DiscretionaryExpensesForm';
import ChildrenEducationExpensesForm from './expenses/ChildrenEducationExpensesForm';
import HealthWellnessExpensesForm from './expenses/HealthWellnessExpensesForm';
import TransportationExpensesForm from './expenses/TransportationExpensesForm';
import PropertyExpensesForm from './expenses/PropertyExpensesForm';*/
import GeneralExpensesForm from './expenses/GeneralExpensesForm';
import AdvancedStepper from '../shared/AdvancedStepper';

import { Context as AppContext } from '../../contexts/AppContext';

const ExpensesStepper = ({ section, mainDispatch = null }) => {
  const { state: { advancedInfo: { expenses } }, dispatch: appDispatch } = useContext(AppContext);

  const getSteps = () => {
    return [
      'General/Living Expenses',
      /* 'Food Expenses',
      'Discretionary Expenses',
      'Children & Education Expenses',
      'Health & Wellness Expenses',
      'Transportation Expenses',
      'Property Expenses' */
    ];
  }

  const getStepContent = (step) => {
    switch (step) {
      /* case 1:
        return <DiscretionaryExpensesForm
          section={section}
          baseObject={expenses}
          appDispatch={appDispatch}
          propKeyName='discretionary'
          values={expenses['discretionary']} />

      case 2:
        return <ChildrenEducationExpensesForm
          section={section}
          baseObject={expenses}
          appDispatch={appDispatch}
          propKeyName='children_education'
          values={expenses['children_education']} />

      case 3:
        return <HealthWellnessExpensesForm
          section={section}
          baseObject={expenses}
          appDispatch={appDispatch}
          propKeyName='health_wellness'
          values={expenses['health_wellness']} />

      case 4:
        return <TransportationExpensesForm
          section={section}
          baseObject={expenses}
          appDispatch={appDispatch}
          propKeyName='transportation'
          values={expenses['transportation']} />

      case 5:
        return <PropertyExpensesForm
          section={section}
          baseObject={expenses}
          appDispatch={appDispatch}
          propKeyName='property'
          values={expenses['property']} />

      default:
        return <FoodExpensesForm
          section={section}
          baseObject={expenses}
          appDispatch={appDispatch}
          propKeyName='food'
          values={expenses['food']} /> */

      default:
        return <GeneralExpensesForm
          section={section}
          baseObject={expenses}
          appDispatch={appDispatch}
          propKeyName='general_living'
          values={expenses['general_living']} />
    }
  }

  return (
    <AdvancedStepper
      steps={getSteps()}
      getStepContent={getStepContent}
      mainDispatch={mainDispatch} />
  );
}

export default ExpensesStepper;