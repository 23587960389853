import React from 'react';
import ApplicantStepper from './advanced/ApplicantStepper';
import ExpensesStepper from './advanced/ExpensesStepper';
import AssetsStepper from './advanced/AssetsStepper';
import LiabilitiesStepper from './advanced/LiabilitiesStepper';
import AdvancedStepper from './shared/AdvancedStepper';
import FinishApplication from './shared/FinishApplication';

const FactFind = () => {
  const getSteps = () => {
    return [
      'Applicants',
      'Expenses',
      'Assets',
      'Liabilities'
    ];
  }

  const getStepContent = (step, mainDispatch) => {
    switch (step) {
      case 1:
        return <ExpensesStepper section={'expenses'} mainDispatch={mainDispatch} />

      case 2:
        return <AssetsStepper section={'assets'} mainDispatch={mainDispatch} />

      case 3:
        return <LiabilitiesStepper section={'liabilities'} mainDispatch={mainDispatch} />

      default:
        return <ApplicantStepper section={'applicants'} mainDispatch={mainDispatch} />
    }
  }

  return (
    <AdvancedStepper
      isVertical
      steps={getSteps()}
      getStepContent={getStepContent}>
      <FinishApplication isAdvanced />
    </AdvancedStepper>
  );
}

export default FactFind;