import React, { useContext } from 'react';
import OwnerOccupiedPropertyDetailsForm from './assets/OwnerOccupiedPropertyDetailsForm';
import InvestmentPropertyDetailsForm from './assets/InvestmentPropertyDetailsForm';
import VehicleDetailsForm from './assets/VehicleDetailsForm';
import BankAccountsForm from './assets/BankAccountsForm';
import OtherAssetsForm from './assets/OtherAssetsForm';
import AdvancedStepper from '../shared/AdvancedStepper';
import AccordionMapper from '../shared/AccordionMapper';

import { Context as AppContext } from '../../contexts/AppContext';
import { types } from '../../hooks/reducers/useAppReducer';

const AssetsStepper = ({ section, mainDispatch = null }) => {
  const { state: { advancedInfo: { assets } }, dispatch: appDispatch } = useContext(AppContext);

  const getSteps = () => {
    return [
      'Owner Occupied Property Details',
      'Investment Property Details',
      'Vehicle Details',
      'Bank Accounts',
      'Other Assets'
    ];
  }

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return <AccordionMapper
          isAdditionable
          section={section}
          baseObject={assets['investment_property_details']}
          appDispatch={appDispatch}
          types={types}
          propKeyName='investment_property_details'
          formComponent={InvestmentPropertyDetailsForm} />

      case 2:
        return <AccordionMapper
          isAdditionable
          section={section}
          baseObject={assets['vehicle_details']}
          appDispatch={appDispatch}
          types={types}
          propKeyName='vehicle_details'
          formComponent={VehicleDetailsForm} />

      case 3:
        return <AccordionMapper
          isAdditionable
          section={section}
          baseObject={assets['bank_accounts']}
          appDispatch={appDispatch}
          types={types}
          propKeyName='bank_accounts'
          formComponent={BankAccountsForm} />

      case 4:
        return <OtherAssetsForm
          section={section}
          baseObject={assets}
          appDispatch={appDispatch}
          types={types}
          next='Finish section'
          propKeyName='other_assets'
          values={assets['other_assets']} />

      default:
        return <OwnerOccupiedPropertyDetailsForm
          section={section}
          baseObject={assets}
          appDispatch={appDispatch}
          types={types}
          propKeyName='owner_occupied_property_details'
          values={assets['owner_occupied_property_details']} />
    }
  }

  return (
    <AdvancedStepper
      steps={getSteps()}
      getStepContent={getStepContent}
      mainDispatch={mainDispatch} />
  );

}

export default AssetsStepper;