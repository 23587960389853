import { useReducer } from "react";
import _ from "lodash";

export const types = {
  SET_STEPPER_PATH: 0,
  UPDATE_STEPPER_PATH: 1,
  MUTATE_STEPPER_PATH: 2,
  RECOVER_PATH_STATE: 3,
  SAVE_DATA: 4,
  RESET: 5,
};

const initState = {
  pathSteps: [],
  skippedSteps: [],
  data: {},
};

const reducer = (state, action) => {
  const { data, pathSteps, skippedSteps } = state;
  const { type, payload } = action;

  let tempSteps = [...pathSteps];
  let tempSkippedSteps = [...skippedSteps];
  let removedSteps = [];

  switch (type) {
    // Replaces current 'pathSteps' value with payload's value
    case types.SET_STEPPER_PATH:
      return {
        ...state,
        pathSteps: payload,
      };

    case types.UPDATE_STEPPER_PATH:
      return {
        ...state,
        pathSteps: [...pathSteps, ...payload],
      };

    case types.MUTATE_STEPPER_PATH:
      payload.stepsToRemove.forEach((item) => {
        const index = tempSteps.findIndex((f) => f.props.keyName === item);

        removedSteps.push({
          index: index + removedSteps.length,
          item: tempSteps[index],
        });

        tempSteps.splice(index, 1);
      });

      return {
        ...state,
        pathSteps: tempSteps,
        skippedSteps: [
          ...skippedSteps,
          {
            skippedIndex: payload.activeStep,
            removedSteps,
          },
        ],
      };

    case types.RECOVER_PATH_STATE:
      const steps = skippedSteps.find((f) => f.skippedIndex === payload);

      if (steps) {
        _.remove(tempSkippedSteps, (f) => f === steps);

        steps.removedSteps.forEach((step) => {
          tempSteps.splice(step.index, 0, step.item);
        });
      }

      return {
        ...state,
        skippedSteps: tempSkippedSteps,
        pathSteps: tempSteps,
      };

    case types.SAVE_DATA:
      return {
        ...state,
        data: {
          ...data,
          [payload.keyName]: payload.value,
        },
      };

    case types.RESET:
      return { ...initState };

    default:
      return state;
  }
};

const useEasyFlowReducer = () => {
  return useReducer(reducer, initState);
};

export default useEasyFlowReducer;
