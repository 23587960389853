import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import moment from 'moment';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDatePicker from '../../shared/controls/CustomDatePicker';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import { residentialStatusOptions } from '../../../utils/selectOptions';

const state = {
  data: {},
  errors: {}
};

const schema = {
  current: {
    address: Joi.string().min(5).max(20).required().label('Current Address'),
    residential_status: Joi.string().required().label('Residential Status'),
    from: Joi.string().min(5).max(20).required().label('From')
  },
  previous: {
    address: Joi.string().min(5).max(20).required().label('Previous Address'),
    residential_status: Joi.string().required().label('Residential Status'),
    from: Joi.string().min(5).max(20).required().label('From')
  },
  mailing: {
    address: Joi.string().min(5).max(20).required().label('Mailing Address')
  },
  post_settlement: {
    address: Joi.string().min(5).max(20).required().label('Post Settlement Address'),
    residential_status: Joi.string().label('Residential Status')
  }
};

const ResidentialDetailsForm = ({ handleChange, values, errors }) => {
  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomInput
            name='current.address'
            label='Current Address'
            value={values ? values.current.address : null}
            error={errors['current.address']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomDropdown
            name='current.residential_status'
            label='Residential Status'
            options={residentialStatusOptions}
            value={values ? values.current.residential_status : null}
            error={errors['current.residential_status']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomDatePicker
            name='current.from'
            label='From'
            value={values ? values.current.from : null}
            error={errors['current.from']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>

      {values.current.from < moment().subtract(3, 'years') &&
        <FormRow>
          <FormColumn>
            <CustomInput
              name='previous.address'
              label='Previous Address'
              value={values ? values.previous.address : null}
              error={errors['previous.address']}
              handleChange={handleChange} />
          </FormColumn>

          <FormColumn>
            <CustomDropdown
              name='previous.residential_status'
              label='Residential Status'
              options={residentialStatusOptions}
              value={values ? values.previous.residential_status : null}
              error={errors['current.residential_stat us']}
              handleChange={handleChange} />
          </FormColumn>

          <FormColumn>
            <CustomDatePicker
              name='previous.from'
              label='From'
              value={values ? values.previous.from : null}
              error={errors['previous.from']}
              handleChange={handleChange} />
          </FormColumn>
        </FormRow>
      }
    </Fragment >
  );
}

export default withForm(ResidentialDetailsForm, state, schema);