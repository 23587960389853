import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const ConstructionFunding = props => {
  return (
    <Fragment>
      <h1></h1>
      <p></p>

      <TileSelector {...props}>
        <Tile value='DA not applied for' />

        <Tile value='Pending DA approval' />

        <Tile value='DA approved' />
      </TileSelector>
    </Fragment>
  );
}

export default ConstructionFunding;