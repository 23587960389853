import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';

const state = {
  data: {},
  errors: {}
};

const schema = {
  general_living: Joi.number().required().empty("").label('General/Living expenses')
};

const GeneralExpensesForm = ({ handleChange, values, errors }) => {
  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <div style={{ display: 'flex', width: '50%', margin: '0 auto' }}>
        <FormColumn>
          <h5>What are your general living expenses? <strong><i>(Not including debts)</i></strong></h5>
          <FormRow>
            <CustomInput
              name={`general_living`}
              label={'General/Living Expenses'}
              value={values ? values['general_living'] : null}
              error={errors['general_living']}
              handleChange={handleChange} />
          </FormRow>
        </FormColumn>
      </div>
    </Fragment>
  );
}

export default withForm(GeneralExpensesForm, state, schema);