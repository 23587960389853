import React, { useState } from 'react';
import { Dropdown, Label } from 'semantic-ui-react'

const CustomDropdown = ({ name, label, data, handleChange, error, value, ...rest }) => {
  const [stateValue, setStateValue] = useState(value);

  // DONE - Refactored using the 'selected' useState hoook;

  const onChange = (item) => {
    setStateValue(item.currentTarget.value);

    handleChange(item);
  }

  return (
    <div className="form-group">
      {/*<label htmlFor={name}>{label}</label>*/}
      <Label pointing='below' prompt>{label}</Label>
      <Dropdown
        fluid
        search
        selection
        clearable
        placeholder={label}
        value={stateValue}
        onChange={(e, { value }) => onChange({ currentTarget: { name, value } })}
        {...rest}
      />
      {/*error && <span className='alert alert-danger'>{error}</span>*/}
    </div>
  );
}

export default CustomDropdown;