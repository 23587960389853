import React, { Fragment } from "react";
import Tile from "../shared/Tile";
import TileSelector from "../shared/TileSelector";
import Title from "../shared/Title";
import Description from "../shared/Description";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import EmojiTransportationOutlinedIcon from "@material-ui/icons/EmojiTransportationOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";

const Welcome = (props) => {
  return (
    <Fragment>
      <Title>Welcome</Title>
      <Description>
        Please select the most relevant option to your needs
      </Description>

      <TileSelector {...props}>
        <Tile value="Asset financial Personal Finance">
          <BusinessCenterIcon className="tile-icon" />
        </Tile>

        <Tile value="Residential">
          <EmojiTransportationOutlinedIcon className="tile-icon" />
        </Tile>

        <Tile value="Other Financial Enquiries">
          <QuestionAnswerOutlinedIcon className="tile-icon" />
        </Tile>

        <Tile value="Commercial">
          <TrendingUpIcon className="tile-icon" />
        </Tile>
      </TileSelector>
    </Fragment>
  );
};

export default Welcome;
