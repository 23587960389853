import React, { Fragment, useState, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import CustomDatePicker from '../../shared/controls/CustomDatePicker';
import { Accordion, Label, Icon } from 'semantic-ui-react';
import { employmentTypeOptions, employmentStatusOptions, employmentBasisOptions } from '../../../utils/selectOptions';

const state = {
  data: {},
  errors: {}
};

const dataSchema = {
  employment_type: Joi.string().max(20).required().label('Employment Type'),
  employment_status: Joi.string().max(20).required().label('Employment Status'),
  employment_basis: Joi.string().max(20).required().label('Employment Basis'),
  role: Joi.string().max(30).required().label('Role'),
  employer_name: Joi.string().max(30).required().label('Employer Name'),
  employer_contact_name: Joi.string().max(20).required().label('Employer Contact Name'),
  employer_phone_number: Joi.number().required().label('Employer Phone Number'),
  employer_address: Joi.string().max(30).required().label('Employer Address'),
  start_date: Joi.date().required().label('Start Date'),
  end_date: Joi.date().label('End Date'),
  employer_abn: Joi.string().max(30).required().label('Employer ABN'),
  employer_acn: Joi.string().max(30).required().label('Employer ACN')
};

const schema = {
  current: dataSchema,
  previous: dataSchema
};

const wrappedForm = (handleChange, values, errors, type) => (
  <Fragment>
    <FormRow>
      <FormColumn>
        <CustomDropdown
          name={`${type}.employment_type`}
          label='Employment Type'
          options={employmentTypeOptions}
          value={values ? values[type]['employment_type'] : null}
          error={errors[`${type}.employment_type`]}
          handleChange={handleChange} />
      </FormColumn>

      <FormColumn>
        <CustomDropdown
          name={`${type}.employment_basis`}
          label='Employment Basis'
          options={employmentBasisOptions}
          value={values ? values[type]['employment_basis'] : null}
          error={errors[`${type}.employment_basis`]}
          handleChange={handleChange} />
      </FormColumn>
    </FormRow>

    <FormRow>
      <FormColumn>
        <CustomInput
          name={`${type}.role`}
          label='Role'
          value={values ? values[type]['role'] : null}
          error={errors[`${type}.role`]}
          handleChange={handleChange} />
      </FormColumn>

      <FormColumn>
        <CustomInput
          name={`${type}.employer_name`}
          label='Employer Name'
          value={values ? values[type]['employer_name'] : null}
          error={errors[`${type}.employer_name`]}
          handleChange={handleChange} />
      </FormColumn>
    </FormRow>

    <FormRow>
      <FormColumn>
        <FormColumn>
          <CustomDatePicker
            name={`${type}.start_date`}
            label='Start Date'
            value={values ? values[type]['start_date'] : null}
            error={errors[`${type}.start_date`]}
            handleChange={handleChange} />
        </FormColumn>

        {type === 'previous' &&
          <FormColumn>
            <CustomDatePicker
              name={`${type}.end_date`}
              label='End Date'
              value={values ? values[type]['end_date'] : null}
              error={errors[`${type}.end_date`]}
              handleChange={handleChange} />
          </FormColumn>
        }
      </FormColumn>
    </FormRow>
  </Fragment>
);

const EmploymentDetailsForm = ({ handleChange, values, errors }) => {
  const [activeIndex] = useState(0);

  useEffect(() => {
    state.data = values;
  }, [values]);

  const getPanels = () => (
    ["current", "previous"].map((item, index) => {
      const type = `${item.slice(0, 1).toUpperCase()}${item.slice(1)}`;

      return ({
        key: `panel-${index}`,
        title: {
          content: (
            <Label as='a' size={"large"}>
              <Icon name='edit' />
              {`${type} Employment`}
            </Label>
          ),
        },
        content: {
          content: (
            wrappedForm(handleChange, values, errors, item)
          ),
        }
      })
    })
  );

  return (
    <Accordion
      styled
      fluid
      defaultActiveIndex={activeIndex}
      panels={getPanels()} />
  );
}

export default withForm(EmploymentDetailsForm, state, schema);