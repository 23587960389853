import React, { Fragment } from 'react';
import Tile from '../../shared/Tile';
import TileSelectorWithArray from '../../shared/TileSelectorWithArray';

const IncomeType = props => (
  <Fragment>
    <p>Type of income</p>

    <TileSelectorWithArray {...props}>
      <Tile value='PAYG Employed (Payslips)' />
      <Tile value='Gov/Pension Centrelink TAC' />
      <Tile value='Self Employed/Other Entity' />
    </TileSelectorWithArray>
  </Fragment >
);

export default IncomeType;