import { useReducer } from "react";

export const types = {
  RESTART: 0,
  REPLACE_ITEM: 1,
  INSERT_ITEM: 2,
  REMOVE_ITEM: 3,
  SET_ITEM: 4,
};

const initState = [];

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.RESTART:
      return initState;

    case types.REPLACE_ITEM:
      console.log(payload);
      return [payload];

    case types.INSERT_ITEM:
      return [...state, payload];

    case types.REMOVE_ITEM:
      const index = state.indexOf(payload);
      const tempState = state.filter((item, i) => i !== index);

      return [...tempState];

    case types.SET_ITEM:
      return payload;

    default:
      return tempState;
  }
};

const useArrayReducer = () => {
  return useReducer(reducer, initState);
};

export default useArrayReducer;
