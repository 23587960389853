import React from 'react';
import { GraphQLClient, ClientContext } from 'graphql-hooks'

const client = new GraphQLClient({
  url: 'http://localhost:1337/graphql'
});

const GraphQLContainer = ({ children }) => {
  return (
    <ClientContext.Provider value={client}>
      {children}
    </ClientContext.Provider>
  );
}

export default GraphQLContainer;