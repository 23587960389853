import React from 'react';

const FormCenteredContainer = ({ children }) => {
  return (
    <div style={{ margin: '25px auto', width: '20%', display: 'flex', justifyContent: 'space-around' }}>
      {children}
    </div>
  );
}

export default FormCenteredContainer;