import React, { Fragment } from 'react';
import TileSelectorWithArray from '../../shared/TileSelectorWithArray';
import Tile from '../../shared/Tile';

const EmploymentType = props => (
  <Fragment>
    <TileSelectorWithArray {...props}>
      <Tile value='Casual' />

      <Tile value='Part-time' />

      <Tile value='Full-time' />
    </TileSelectorWithArray>
  </Fragment>
);

export default EmploymentType;