import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { Label } from 'semantic-ui-react';

import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ name, label, data, handleChange, value, error, ...rest }) => {
  const [selected, setSelected] = useState(value ?? null);

  const onChange = (item) => {
    setSelected(item.currentTarget.value);

    handleChange(item);
  }

  return (
    <div className='form-group'>
      <Label pointing='below' prompt>{label}</Label>
      <DatePicker
        name={name}
        showYearDropdown
        dateFormat="MMMM dd, yyyy"
        className="form-control"
        selected={selected}
        onChange={date => onChange({ currentTarget: { name, value: date } })}
        {...rest}
      />
      {/*error && <span className='alert alert-danger'>{error}</span>*/}
    </div>
  );
}

export default CustomDatePicker;