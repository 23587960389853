import React, { Fragment } from 'react';
import Tile from '../../shared/Tile';
import TileSelector from '../../shared/TileSelector';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const ApplicantsNumber = props => {
  return (
    <Fragment>
      <p>How many applicants will be in this application?</p>

      <TileSelector {...props}>
        <Tile value='1 Applicant'>
          <EmojiPeopleIcon className='tile-icon' />
        </Tile>

        <Tile value='2 Applicants'>
          <GroupIcon className='tile-icon' />
        </Tile>

        <Tile value='3 Applicants'>
          <GroupAddIcon className='tile-icon' />
        </Tile>

        <Tile value='4 Applicants'>
          <GroupAddIcon className='tile-icon' />
        </Tile>
      </TileSelector>
    </Fragment>
  );
}

export default ApplicantsNumber;