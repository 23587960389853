import React, { Fragment } from 'react';
import CustomCheckBox from '../../shared/controls/CustomCheckBox';

const AuthorizeInformationUsage = ({ keyName, allowNextStep }) => {
  const accept = value => {
    allowNextStep(value);
  }

  return (
    <Fragment>
      <h3>Do you authorize BorgFinancial to use your information?</h3>
      <CustomCheckBox
        name='Accept'
        handleChange={(e) => accept(e.currentTarget.value)}
      />
    </Fragment>
  );
}

export default AuthorizeInformationUsage;