import React, { Fragment, useState, useRef } from "react";
import IncomeType from "./IncomeType";
import EmploymentType from "./EmploymentType";
import TraderType from "./TraderType";
import TileSelector from "../../shared/TileSelector";
import HorizontalList from "../../shared/HorizontalList";
import { useEffect } from "react";

const IncomeTypeSelector = ({ savePanelData, ...rest }) => {
  const [incomeType, setIncomeType] = useState(null);
  const [employmentType, setEmploymentType] = useState(null);
  const [traderType, setTraderType] = useState(null);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const data = {
      income_type: incomeType,
      employment_type: employmentType,
      trader_type: traderType,
    };

    savePanelData(data);
  }, [incomeType, employmentType, traderType]);

  return (
    <Fragment>
      <IncomeType
        {...rest}
        keyName="income_type"
        multiple
        saveOnChange
        saveData={(value) => setIncomeType(value)}
        previousAnswer={
          rest.previousAnswer ? rest.previousAnswer["income_type"] : null
        }
      />

      <HorizontalList>
        {incomeType &&
          incomeType.indexOf("PAYG Employed (Payslips)") !== -1 && (
            <EmploymentType
              {...rest}
              keyName="employment_type"
              multiple
              saveOnChange
              saveData={(value) => setEmploymentType(value)}
              previousAnswer={
                rest.previousAnswer
                  ? rest.previousAnswer["employment_type"]
                  : null
              }
            />
          )}

        {incomeType &&
          incomeType.indexOf("Self Employed/Other Entity") !== -1 && (
            <TraderType
              {...rest}
              keyName="trader_type"
              multiple
              saveOnChange
              saveData={(value) => setTraderType(value)}
              previousAnswer={
                rest.previousAnswer ? rest.previousAnswer["trader_type"] : null
              }
            />
          )}
      </HorizontalList>
    </Fragment>
  );
};

export default IncomeTypeSelector;
