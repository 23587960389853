import React, { Fragment } from 'react';
import TileSelector from '../../shared/TileSelector';
import Tile from '../../shared/Tile';

const CommercialType = props => {
  return (
    <Fragment>
      <h1></h1>
      <p></p>

      <TileSelector {...props}>
        <Tile value='Residential Zoned' />

        <Tile value='Commercial Zoned' />

        <Tile value='Purchase' />
      </TileSelector>
    </Fragment>
  );
}

export default CommercialType;