import React, { createContext } from 'react';
import useEasyFlowReducer from '../hooks/reducers/useEasyFlowReducer';

export const Context = createContext();

const EasyFlowContext = ({ children }) => {
  const [state, dispatch] = useEasyFlowReducer();

  return (
    <Context.Provider value={{ state, dispatch }}>
      {children}
    </Context.Provider>
  );
}

export default EasyFlowContext;