import React from 'react';
import { Button } from 'semantic-ui-react';

const styles = {
  backgrounColor: 'rgb(0, 28, 84)',
  color: 'rgb(255, 255, 255)'
};

const CustomButton = ({ label, disabled, onClick }) => {
  /**
 * General component description in JSDoc format. Markdown is *supported*.
 */

  return (
    <div className="col text-center">
      <Button style={styles} onClick={onClick} className="btn btn-primary" disabled={disabled}>
        {label}
      </Button>
    </div>
  );
}

export default CustomButton;