import React from 'react';

const styles = {
  listStyle: 'none',
  display: 'flex',
  margin: '20px auto auto',
  padding: '0',
  flexWrap: 'wrap',
  justifyContent: 'center'
};

const HorizontalList = ({ children }) => (
  <ul style={styles}>
    {children}
  </ul>
);

export default HorizontalList;