import { useReducer } from 'react';
import _ from 'lodash';

const applicantModel = () => ({
  basic_information: {},
  contact_details: {
    mobile_phone: null,
    primary_email: ''
  },
  residential_details: {
    current: {},
    previous: {},
    mailing: {},
    post_settlement: {}
  },
  identification: {
    driver_licence: {}
  },
  next_of_kin: {},
  employment_details: {
    current: {},
    previous: {}
  },
  income: {
    gross_salary: {},
    allowance: {},
    bonus: {},
    commission: {},
    overtime_essential: {},
    overtime_non_essential: {},
    business_income: {
      last_tax_year: {},
      previous_tax_year: {}
    },
    total_income: {}
  }
});

const commonModel = () => ({
  applicant: '',
  type: '',
  details: '',
  institution: '',
  membership_number: 0,
  ownership: '',
  value: 0
});

const initState = {
  createdAt: Date.now(),
  isAdvancedInterested: false,
  isFinished: false,
  basicInfo: {},
  advancedInfo: {
    applicants: [
      applicantModel(),  // TODO: TEMP WHILE FIXING BASIC PATH
      applicantModel()   // TODO: TEMP WHILE FIXING BASIC PATH
    ],
    expenses: {
      /* food: {
        food_supermarket: {},
        coffee_lunch_takeaway: {},
        cigarettes_alcohol: {}
      },
      discretionary: {
        clothing_shoes_accessories: {},
        phone_internet_tv: {},
        hairdressing_grooming: {},
        entertainment: {},
        gifts_celebrations: {},
        holiday: {},
        pets: {},
        other_discretionary: {}
      },
      children_education: {
        school_uniforms: {},
        child_care: {},
        child_maintenance: {}
      },
      health_wellness: {
        gym_sport: {},
        private_health_insurance: {},
        doctor_dentist_pharmacy: {},
        glasses_eye_care: {},
        life_trauma: {},
        other_health_wellness: {}
      },
      transportation: {
        motor_vehicle_insurance: {},
        motor_vehicle_registration: {},
        fuel_tolls_parking: {},
        repairs_servicing_tyres: {},
        bus_trains_tram_ferry: {},
        car_share_uber_taxi: {}
      },
      property: {
        current_rental: {},
        home_contents_insurance: {},
        electricity_gas_water: {},
        repairs_maintenance_pool: {},
        rates_body_corp_oo: {},
        rates_body_corp_inv: {}
      } */
    },
    assets: {
      owner_occupied_property_details: {},
      investment_property_details: [{}],
      vehicle_details: [{}],
      bank_accounts: [{}],
      other_assets: {
        home_contents: {},
        superfunds: [
          commonModel(),  // TODO: TEMP WHILE FIXING BASIC PATH
          commonModel()   // TODO: TEMP WHILE FIXING BASIC PATH   
        ],
        others: [
          commonModel(),
          commonModel()
        ]
      }
    },
    liabilities: {
      mortgage_loans: [{}],
      credit_cards: [{}],
      vehicle_loans: [{}],
      other_loans: [{}]
    }
  }
};

export const types = {
  IS_ADVANCED_INTERESTED: 0,
  IS_FINISHED: 1,
  SAVE_BASIC_DATA: 2,
  SAVE_ADVANCED_DATA: 3,
  ADD_ITEM_TO_ARRAY: 4,
  SET_APPLICANTS: 5,
  RESET: 6
};

const reducer = (state, { type, payload }) => {
  const { advancedInfo } = state;

  console.log(`[${type}]`);

  if (payload)
    console.log(`[${type}] - payload.path: `, payload.path);

  console.log(`[${type}] - PRE-UPDATE => advancedInfo: `, advancedInfo);

  switch (type) {
    case types.IS_ADVANCED_INTERESTED:
      return {
        ...state,
        isAdvancedInterested: true
      };

    case types.IS_FINISHED:
      return {
        ...state,
        isFinished: true
      };

    case types.SAVE_BASIC_DATA:
      return {
        ...state,
        basicInfo: { ...payload }
      };

    case types.SAVE_ADVANCED_DATA:
      _.set(advancedInfo, payload.path, payload.value);

      return {
        ...state,
        advancedInfo: {
          ...advancedInfo
        }
      };

    case types.ADD_ITEM_TO_ARRAY:
      const array = _.get(advancedInfo, payload);
      array.push({});

      _.set(advancedInfo, payload, array);

      return {
        ...state,
        advancedInfo: {
          ...advancedInfo
        }
      };

    case types.SET_APPLICANTS:
      const { advancedInfo: advancedInfoCopy } = { ...state };
      const { applicants: applicantsCopy, assets: assetsCopy } = advancedInfoCopy;
      const { other_assets: otherAssetsCopy } = assetsCopy;
      const { superfunds: superfundsCopy } = otherAssetsCopy;

      for (let i = 0; i < payload; i++) {
        applicantsCopy.push(applicantModel());
        superfundsCopy.push(commonModel());
      }

      return {
        ...state,
        advancedInfo: {
          ...advancedInfoCopy,
          applicants: [...applicantsCopy],
          assets: {
            ...assetsCopy,
            other_assets: {
              ...otherAssetsCopy,
              superfunds: [...superfundsCopy]
            }
          }
        }
      };

    case types.RESET:
      return { ...initState };

    default:
      return state;
  }
}

const useAppReducer = () => useReducer(reducer, initState);

export default useAppReducer;