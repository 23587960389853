import React, { Fragment, useEffect } from 'react';
import Joi from '@hapi/joi';
import withForm from '../../shared/form/withForm';
import FormRow from '../../shared/form/FormRow';
import FormColumn from '../../shared/form/FormColumn';
import CustomInput from '../../shared/controls/CustomInput';
import CustomDropdown from '../../shared/controls/CustomDropdown';
import CustomDatePicker from '../../shared/controls/CustomDatePicker';
import { vehicleTypeOptions } from '../../../utils/selectOptions';

const state = {
  data: {},
  errors: {}
};

const schema = {
  make_model: Joi.string().required().label(`Vehicle ${null} Make & Model`),
  type: Joi.string().required().label(`Vehicle Type`),
  estimated_build_date: Joi.date().label(`Estimated Vehicle Build Date`),
  value: Joi.number().required().label(`Value`)
};

const VehicleDetailsForm = ({ handleChange, values, errors, vehiclesLength }) => {
  useEffect(() => {
    state.data = values;
  }, [values]);

  return (
    <Fragment>
      <FormRow>
        <FormColumn>
          <CustomInput
            name='make_model'
            label={`Vehicle ${vehiclesLength} Make & Model`}
            value={values ? values['make_model'] : null}
            error={errors['make_model']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomDropdown
            name='type'
            label='Vehicle Type'
            options={vehicleTypeOptions}
            value={values ? values['type'] : null}
            error={errors['type']}
            handleChange={handleChange} />
        </FormColumn>

        <FormColumn>
          <CustomDatePicker
            name={'estimated_build_date'}
            label='Estimated Vehicle Build Date'
            value={values ? values['estimated_build_date'] : null}
            error={errors['estimated_build_date']}
            handleChange={handleChange}
          />
        </FormColumn>

        <FormColumn>
          <CustomInput
            name='value'
            label='Value'
            value={values ? values['value'] : null}
            error={errors['value']}
            handleChange={handleChange} />
        </FormColumn>
      </FormRow>
    </Fragment>
  );
}

export default withForm(VehicleDetailsForm, state, schema);